import React, { useState, useEffect } from "react";
import "./FilterModal.css";
import axios from "axios";

const FilterModal = ({ isOpen, onClose, onApply, setFiles, setFolders }) => {
  const [token, setToken] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    folder: false,
    normalDocument: false,
    contract: false,
    invoice: false,
  });
  const [fileType, setFileType] = useState([]);
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [rangeDate, setRangeDate] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const [activeTab, setActiveTab] = useState("FileType");


  const clearFilters = async () => {
    setSelectedFilters({
      folder: false,
      normalDocument: false,
      contract: false,
      invoice: false,
    });
    setFileType([]);
    setFromDate("");
    setToDate("");
    setRangeDate("");
  
    try {
      // Fetch default files and folders without any filters
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/files-and-folders/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const { files, folders } = response.data;
  
        setFiles(files || []);
        setFolders(folders || []);
      }
    } catch (error) {
      console.error("Error fetching default data:", error);
    }
    finally {
      
      onClose();
    }
  };
  

const handleApply = async () => {
  try {
    const params = {
      file_type: fileType.length > 0 ? fileType.join(",") + "," : undefined,
      ...(rangeDate
        ? { date_range: rangeDate }
        : {
            from_date: fromDate || undefined,
            to_date: toDate || undefined,
          }),
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/readpdf/filter-files-and-folders/`,
      {
        params,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const filteredFiles = response.data.files
        .map((file) => ({
            ...file,
            pdf_url: file.url || file.pdf_url || "",
            document_type: file.type || "Unknown", 
        }));

        const filteredFolders = response.data.folders.map((folder) => ({
          ...folder,
          file_type: "Folder",
          documents: folder.documents.map((doc) => ({
              ...doc,
              pdfUrl: doc.url || doc.file_url, 
              pdfKey: doc.id,
          })),
      }));
      setFolders(filteredFolders);
      


    setFiles(filteredFiles);
    setFolders(filteredFolders);
      setFileType([]);
      setFromDate("");
      setToDate("");
      setRangeDate("");
      setFolders(filteredFolders || []);
      setFiles(filteredFiles || []);
    }
  } catch (err) {
    console.error("Error while applying filter:", err);
  }
  onClose();
};

  
  
  

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    setRangeDate(value);
    const today = new Date();
    if (value === "today") {
      setFromDate(today.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    } else if (value === "last7days") {
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 7);
      setFromDate(last7Days.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    } else if (value === "thisyear") {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      setFromDate(startOfYear.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    }
  };
  const handleCheckboxChange = (type) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev, [type]: !prev[type] };
  
      setFileType(() => {
        const fileTypeMapping = {
          folder: "folder",
          normalDocument: "normal_document",
          contract: "contract",
          invoice: "invoice",
        };
  
        return Object.keys(updatedFilters)
          .filter((key) => updatedFilters[key])
          .map((key) => fileTypeMapping[key]);
      });
  
      return updatedFilters;
    });
  };
  
  
  
  
  const safeToLowerCase = (str) => {
    return str && typeof str === 'string' ? str.toLowerCase() : ''; 
  };
  

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay-doc">
      <div className="filter-modal-doc">
      <button className="close-button-folder" onClick={onClose}>&times;</button>
        <h2>Filter By</h2>
        <div className="Display">
          <div className="Box-rec">
           
            <button
              className={`Box-doc ${activeTab === "FileType" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("FileType")}
            >
              <img src="./File-type.svg" alt="" />
              File Type
            </button>
           
          </div>
          {activeTab === "FileType" && (
            <div className="filter-options">
              <div className="Header-doc-filter-folder">
                <p>Select the type of files to include in the results</p>
              </div>
              <label>
  <input
    type="checkbox"
    checked={selectedFilters.folder}
    onChange={() => handleCheckboxChange("folder")}
    className="chekcbox-doc-filter"
  />
  Folder
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.normalDocument}
    onChange={() => handleCheckboxChange("normalDocument")}
    className="chekcbox-doc-filter"
  />
  All Document
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.contract}
    onChange={() => handleCheckboxChange("contract")}
    className="chekcbox-doc-filter"
  />
  Contract
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.invoice}
    onChange={() => handleCheckboxChange("invoice")}
    className="chekcbox-doc-filter"
  />
  Invoice
</label>

            </div>
          )}

        </div>
        <div className="filter-actions-folder">
        <button onClick={clearFilters} className="clear-all-filter">
  Clear All
</button>          <button onClick={handleApply}className="apply-filter">Apply filter</button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
