import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./Invoice_Details.css";
import { Header } from "../../components/Header/Header";
import LineItems from "./LineItems";
function Invoice_Details() {
  const location = useLocation();
  const { invoiceKey, extractedData } = location.state || {};
  console.log("Invoice Key:", invoiceKey);
  const handleGoToInvoices = () => {
    navigate("/Invoices");
  };
  const [activeEditField, setActiveEditField] = useState(null);
  const [userNote, setUserNote] = useState("");
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [openSections, setOpenSections] = useState({
    termsAndConditions: false,
    financialTerms: false,
    legalClauses: false,
  });
  const [isKeyDatesOpen, setIsKeyDatesOpen] = useState(false);
  const editRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);

  const handleInputChange = (field, value) => {
    console.log(`Updating field: ${field} with value: ${value}`);
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        if (activeEditField) {
          handleSaveOnBlur(activeEditField);
          setActiveEditField(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [activeEditField, editRef]);

  const handleFieldEdit = (field) => {
    setActiveEditField(field);
  };

  const handleEdit = () => {
    if (edit === true) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  };
  const handleSaveOnBlur = async (field) => {
    if (!editedData[field]) {
      setActiveEditField(null);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.log("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/invoice/edit-invoice-details/${invoiceKey}/`,
        {
          edited_data: { [field]: editedData[field] },
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setInvoiceData((prevData) => ({
          ...prevData,
          [field]: editedData[field],
        }));
      }
    } catch (error) {
      console.error(
        "Error saving field:",
        error.response?.data || error.message
      );
    } finally {
      setActiveEditField(null);
    }
  };

  const saveNote = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }
    setIsSaving(true);

    try {
      const filteredEditedData = Object.fromEntries(
        Object.entries(editedData).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/invoice/edit-invoice-details/${invoiceKey}/`,
        {
          note: userNote,
          edited_data: filteredEditedData,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const updatedNote = response.data.data.note;
        setInvoiceData((prevData) => ({
          ...prevData,
          note: updatedNote,
        }));
        setUserNote(updatedNote);
        setEditedData({});
      } else {
        console.error(
          "Failed to save note:",
          response.data.msg || "Unknown error."
        );
        alert(response.data.msg || "Failed to save the note.");
      }
    } catch (error) {
      console.error(
        "Error saving note:",
        error.response?.data || error.message
      );
      alert("An error occurred while saving the note.");
    } finally {
      setIsSaving(false);
      setButtonVisible(false);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (!invoiceKey) return;
    console.log("Invoice Key:", invoiceKey);

    const fetchInvoiceDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/readpdf/extract-invoice/`,
          { pdf_key: invoiceKey },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const fetchedInvoiceData = response.data.invoice_data;
          setInvoiceData(fetchedInvoiceData);
          setInvoiceUrl(response.data.invoice_url);
          setEditedData(fetchedInvoiceData);
          setUserNote(fetchedInvoiceData?.note || "");
          console.log(
            "Invoice details fetched successfully:",
            fetchedInvoiceData
          );
        } else {
          setError(response.data.msg || "Failed to fetch invoice details");
        }
      } catch (error) {
        console.error(
          "Error fetching invoice details:",
          error.response?.data || error.message
        );
        setError("An error occurred while fetching invoice details.");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoiceKey]);

  if (loading) {
    return <p>Loading invoice details...</p>;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  const toggleTerms = () => {
    setIsTermsOpen((prev) => !prev);
  };

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const {
    "Invoice ID": invoiceId,
    "Invoice title": invoiceTitle,
    "Due date": dueDate,
    "Issued on": issuedOn,
    "Vendor name": vendorName,
    "Vendor address": vendorAddress,
    "Vendor email": vendorEmail,
    "Vendor telephone": vendorTelephone,
    "Customer name": customerName,
    "Customer address": customerAddress,
    "Customer telephone": customerTelephone,
    "Customer email": customerEmail,
    "Total due amount": totalAmount,
    "Payment status": paymentStatus,
    "Terms and conditions": termsAndConditions,
    "Line items": lineItems = [],
  } = invoiceData || {};

  return (
    <div className="full_Container">
      <div className="header-container">
        <Header
          icon="/arrow-back.svg"
          name="Go to Invoices"
          askCoPiolt={true}
          invoiceKey={invoiceKey}
          invoiceUrl={invoiceUrl}
          onGoToInvoices={handleGoToInvoices}
        />
      </div>
      <div className="contract-details">
        <header className="header">
          <div className="header-content">
            <div className="contract-info">
              <img
                src="/hicon.svg"
                alt="icon"
                className="contract-icon-invoice"
              />
              <div>
                <h1 className="contract-title">
                  {invoiceTitle || "Invoice Details"}
                </h1>
                <p className="contract-type">
                  Invoice ID: <span>{invoiceId || "N/A"}</span>
                </p>
                <p className="contract-type">
                  Issued On: <span>{issuedOn || "N/A"}</span>
                </p>
                <p className="contract-type">
                  Due On: <span>{invoiceData?.["Due in"] || "N/A"}</span>
                </p>
              </div>
            </div>
            <button
              onClick={openPopup}
              className="view-contract-button-contract"
            >
              <span className="button-icon">
                <img src="/eye.svg" alt="" />
              </span>
              View Invoice
            </button>
          </div>
        </header>
        {invoiceUrl && isPopupOpen && (
          <div className="contract-modal-overlay" onClick={closePopup}>
            <div
              className="contract-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={closePopup}></button>
              <iframe
                src={invoiceUrl}
                title="Invoice Document"
                className="contract-iframe"
              ></iframe>
            </div>
          </div>
        )}
        <h4 className="customer-header">
          <span role="img" aria-label="icon">
            <img src="/custDetails.svg" alt="" />
          </span>
          Vendor & Customer Information
        </h4>
        <section className="customer-details">
          <div className="customer-cards">
            <div className="customer-card" ref={editRef}>
              <div className="flex items-center relative w-full gap-2">
                <span className="party-label">Vendor Information</span>
                {!edit && (
                  <img
                    src="/edit-icon.svg"
                    alt="Edit"
                    className="edit-icon cursor-pointer w-[20px] h-[20px] absolute right-[10px] top-1/2 transform -translate-y-1/2"
                    onClick={() => setEdit(true)}
                  />
                )}
              </div>

              <div className="signatory">
                <strong>Primary Contact:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[8px] font-outfit"
                    type="text"
                    value={
                      editedData["Vendor name"] !== undefined
                        ? editedData["Vendor name"]
                        : vendorName || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Vendor name", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Vendor name");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span className="cust-details">{vendorName || "N/A"}</span>
                )}
              </div>

              <p className="signatory" style={{
    display: "flex",
    alignItems: "flex-start",
  }}>
                <strong  style={{
      width: "60px", // Fixed width ensures label alignment
      textAlign: "left", // Ensures text remains aligned properly
    }}>Address:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[312px] h-[65px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Vendor address"] !== undefined
                        ? editedData["Vendor address"]
                        : vendorAddress || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Vendor address", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Vendor address");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                  className="cust-details"
                  style={{
                    marginLeft: "75px",
                    marginTop:"1px",
                    flex: "1",
                    display: "inline-block",
                   
                  }}
                  >
                    {vendorAddress || "N/A"}
                  </span>
                )}
              </p>

              <div className="signatory">
                <strong>Phone Number:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Vendor telephone"] !== undefined
                        ? editedData["Vendor telephone"]
                        : vendorTelephone || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Vendor telephone", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Vendor telephone");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                    className="phone-data"
                    style={{
                      marginLeft: "30px",
                      display: "inline-block",
                      marginTop: "-17px",
                    }}
                  >
                    {vendorTelephone || "N/A"}
                  </span>
                )}
              </div>

              <div className="signatory">
                <strong>Email Address:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Vendor email"] !== undefined
                        ? editedData["Vendor email"]
                        : vendorEmail || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Vendor email", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Vendor email");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                    className="email-data"
                    style={{
                      marginLeft: "40px",
                      display: "inline-block",
                      marginTop: "-17px",
                    }}
                  >
                    {vendorEmail || "N/A"}
                  </span>
                )}
              </div>
            </div>

            <div className="customer-card">
              <div className="flex items-center relative w-full gap-2">
                <span className="party-label">Customer Information</span>
                {!edit && (
                  <img
                    src="/edit-icon.svg"
                    alt="Edit"
                    className="edit-icon cursor-pointer w-[20px] h-[20px] absolute right-[10px] top-1/2 transform -translate-y-1/2"
                    onClick={() => setEdit(true)}
                  />
                )}
              </div>

              <p className="signatory">
                <strong>Primary Contact:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[8px] font-outfit"
                    type="text"
                    value={
                      editedData["Customer name"] !== undefined
                        ? editedData["Customer name"]
                        : customerName || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Customer name", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Customer name");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span className="cust-details">{customerName || "N/A"}</span>
                )}
              </p>

              <p className="signatory" style={{
    display: "flex",
    alignItems: "flex-start",
  }}>
                <strong  style={{
      width: "60px", 
      textAlign: "left", 
    }}>Address:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[312px] h-[65px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Customer address"] !== undefined
                        ? editedData["Customer address"]
                        : customerAddress || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Customer address", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Customer address");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                    className="cust-details"
                    style={{
                      marginLeft: "75px",
                      display: "inline-block",
                      marginTop: "1px",
                    }}
                  >
                    {customerAddress || "N/A"}
                  </span>
                )}
              </p>

              <div className="signatory">
                <strong>Phone Number:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Customer telephone"] !== undefined
                        ? editedData["Customer telephone"]
                        : customerTelephone || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Customer telephone", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Customer telephone");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                    className="phone-data"
                    style={{
                      marginLeft: "30px",
                      display: "inline-block",
                      marginTop: "-17px",
                    }}
                  >
                    {customerTelephone || "N/A"}
                  </span>
                )}
              </div>

              <div className="signatory">
                <strong>Email Address:</strong>{" "}
                {edit ? (
                  <input
                    className="w-[267px] h-[27px] rounded-lg border border-[rgba(0,0,0,0.1)] bg-[#FEF9F7] outline-none text-[15px] font-medium leading-normal text-[#73607B] pb-[10px] font-outfit"
                    type="text"
                    value={
                      editedData["Customer email"] !== undefined
                        ? editedData["Customer email"]
                        : customerEmail || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Customer email", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Customer email");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <span
                    className="email-data"
                    style={{
                      marginLeft: "40px",
                      display: "inline-block",
                      marginTop: "-17px",
                    }}
                  >
                    {customerEmail !== "null" ? customerEmail : "N/A"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="key-dates-wrapper" ref={editRef}>
          <div className="key-dates">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => toggleSection("keyDates")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src="/invoice-paylogo.svg" alt="Key Dates Icon" />
                <h3>Payment Information</h3>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                {isKeyDatesOpen && !edit && (
                  <img
                    src="/edit-icon.svg"
                    alt="Edit"
                    style={{
                      cursor: "pointer",
                      width: "22px",
                      height: "22px",
                    }}
                    onClick={() => setEdit(true)}
                  />
                )}
                <img
                  src={isKeyDatesOpen ? "/up-arrow.svg" : "/down-arrow.svg"}
                  alt="Toggle Dropdown"
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={() => setIsKeyDatesOpen((prev) => !prev)}
                />
              </div>
            </div>
            {isKeyDatesOpen && (
              <div className="date-details" style={{ borderBottom: "none" }}>
                <p className="effective">
                  Total Due:{" "}
                  {edit ? (
                    <input
                      className="unique-input "
                      type="text"
                      value={
                        editedData["Total due amount"] !== undefined
                          ? editedData["Total due amount"]
                          : totalAmount || ""
                      }
                      onChange={(e) =>
                        handleInputChange("Total due amount", e.target.value)
                      }
                      onBlur={() => {
                        handleSaveOnBlur("Total due amount");
                        setEdit(false);
                      }}
                    />
                  ) : (
                    <span className="keyValue " style={{ color: "#8B55DD" }}>
                      {totalAmount || "N/A"}
                    </span>
                  )}
                </p>

                <p className="effective">
                  Payment Status:{" "}
                  {edit ? (
                    <select
                      className="w-[150px] h-[30px] border border-gray-300 rounded bg-[#FEF9F7] text-[13px] font-medium outline-none"
                      value={
                        editedData["Payment status"] !== undefined
                          ? editedData["Payment status"]
                          : paymentStatus || ""
                      }
                      onChange={(e) =>
                        handleInputChange("Payment status", e.target.value)
                      }
                      onBlur={() => {
                        handleSaveOnBlur("Payment status");
                        setEdit(false);
                      }}
                    >
                      <option value="Paid" className="text-[#28D9AA]">
                        Paid
                      </option>
                      <option value="Pending" className="text-[#BFD70D]">
                        Pending
                      </option>
                      <option
                        value="Overdue"
                        className="text-[rgba(226,25,25,0.80)]"
                      >
                        Overdue
                      </option>
                    </select>
                  ) : (
                    <span
                      className={`${
                        paymentStatus === "Paid"
                          ? "text-[#28D9AA]"
                          : paymentStatus === "Pending"
                          ? "text-[#BFD70D]"
                          : paymentStatus === "Overdue"
                          ? "text-[rgba(226,25,25,0.80)]"
                          : "text-gray-700"
                      }`}
                    >
                      {paymentStatus || "N/A"}
                    </span>
                  )}
                </p>

                <p className="effective">
                  Due in:{" "}
                  {edit ? (
                    <input
                      className="unique-input"
                      type="text"
                      value={
                        editedData["Due in"] !== undefined
                          ? editedData["Due in"]
                          : "N/A"
                      }
                      onChange={(e) =>
                        handleInputChange("Due in", e.target.value)
                      }
                      onBlur={() => {
                        handleSaveOnBlur("Due in");
                        setEdit(false);
                      }}
                    />
                  ) : (
                    <span className="keyValue">
                      {invoiceData?.["Due in"] || "N/A"}
                    </span>
                  )}
                </p>
              </div>
            )}
          </div>
        </section>
        <LineItems
          invoiceKey={invoiceKey}
          initialLineItems={invoiceData?.["Line items"]}
          updateLineItems={(updatedLineItems) =>
            setInvoiceData((prevData) => ({
              ...prevData,
              "Line items": updatedLineItems,
            }))
          }
        />
        ;
        <section className="terms-and-conditions-section">
          <div className="accordion-item">
            <div
              className="accordion-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => setIsTermsOpen(!isTermsOpen)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src="/term.svg" alt="" className="icon" />
                <p className="Terms-condition-title">Terms and Conditions</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                {isTermsOpen && !edit && (
                  <img
                    src="/edit-icon.svg"
                    alt="Edit"
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      height: "20px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEdit(true);
                    }}
                  />
                )}
                <img
                  src={isTermsOpen ? "/up-arrow.svg" : "/down-arrow.svg"}
                  alt={isTermsOpen ? "Collapse" : "Expand"}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            </div>
            {isTermsOpen && (
              <div
                className="accordion-content"
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                }}
              >
                {edit ? (
                  <textarea
                    className="w-full h-[150px] rounded-lg border border-gray-300 bg-[#FEF9F7] p-2 outline-none text-sm text-gray-800"
                    value={
                      editedData["Terms and conditions"] !== undefined
                        ? editedData["Terms and conditions"]
                        : termsAndConditions || ""
                    }
                    onChange={(e) =>
                      handleInputChange("Terms and conditions", e.target.value)
                    }
                    onBlur={() => {
                      handleSaveOnBlur("Terms and conditions");
                      setEdit(false);
                    }}
                  />
                ) : (
                  <p>
                    {termsAndConditions || "No terms and conditions available."}
                  </p>
                )}
              </div>
            )}
          </div>
        </section>
        <section className="notes-section">
          <div className="notes-item">
            <div
              className="notes-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="notes" style={{ margin: 0 }}>
                <span>
                  <img src="/notes.svg" alt="" />
                </span>{" "}
                Notes
              </p>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <textarea
                placeholder="Enter your comments here..."
                className="notes-input"
                style={{
                  width: "95%",
                  marginTop: "10px",
                  padding: "10px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  position: "relative",
                }}
                value={userNote}
                onChange={(e) => {
                  setUserNote(e.target.value);
                  if (!isButtonVisible) setButtonVisible(true);
                }}
              ></textarea>
              {isButtonVisible && (
                <button
                  className="save-button"
                  onClick={() => {
                    setIsSaving(true);
                    saveNote();
                    setTimeout(() => {
                      setIsSaving(false);
                      setButtonVisible(false);
                    }, 2000);
                  }}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    padding: "6px 12px",
                    backgroundColor: "#C95EBE",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Invoice_Details;
