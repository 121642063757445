import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Homepage from "../pages/home/Homepage";
import FolderContents from "../pages/Contract/FolderContents";
import FolderChat from "../pages/Contract/FolderChat";
import Chat from "../pages/chat/Chat";
import ContractDetails from "../pages/Contract/ContractDetails";
import Pricing from "../pages/pricing/Pricing";
import PrivacyPage from "../pages/Privacy/PrivacyPage";
import TermAndCondition from "../pages/Term&Condition/TermAndCondition";
import Signin from "../pages/Login&Signup/Signin";
import BlogPage from "../pages/Blog/BlogPage";
import BlogDetails from "../pages/Blog/BlogDetails";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import Footer from "../pages/Footer/Footer";
import SignupPage from "../pages/Login&Signup/SignupPage";
import UploadFilePage from "../pages/UploadFile/UploadFilePage";
import ChatTest from "../pages/chat/ChatTest";
import Menu from "../pages/chat/Menu";
import Layout from "../pages/chat/Layout";
import Setting from "../pages/chat/setting/Setting";
import PaymentSuccess from "../pages/pricing/PaymentSuccess";
import Contract from "../pages/Contract/Contract_Data";
import ChatApp from "../components/ChatApp";
import ChatContract from "../components/ChatContract";
import Invoice_chat from "../pages/Invoices/Invoice_chat";
import PdfChat from "../components/pdfChat";
import UploadProgress from "../pages/Contract/UploadProgress";
import PaymentFailure from "../pages/pricing/PaymentFailure";
import UploadExcel from "../pages/chat/Excel_chat/UploadExcel";
import UploadYoutubeUrl from "../pages/chat/Youtube/UploadYoutubeUrl";
import YoutubeSummary from "../pages/chat/Youtube/YoutubeSummary";
import ExcelChat from "../pages/chat/Excel_chat/ExcelChat";
import { ForgotPassword } from "../pages/Login&Signup/Forgot_password/ForgotPassword";
import { ResetPassword } from "../pages/Login&Signup/Forgot_password/ResetPassword";
import { CreatePassword } from "../pages/Login&Signup/Forgot_password/CreatePassword";
import { CompleteAccount } from "../pages/Login&Signup/Complete_account/CompleteAccount";
import OtpPage from "../pages/Login&Signup/OtpSection/OtpPage";
import { AIcopilot } from "../pages/AI_Copilot/AIcopilot";
import ContractChat from "../pages/Contract/contractChat";
import Invoices from "../pages/Invoices/Invoice_Data";
import  Invoice_Details  from "../pages/Invoices/Invoice_Details";
import CopilotChat from "../pages/AI_Copilot/CopilotChat";

const MainRoutes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };
  const isFooterVisible =
    ["/", "/pricing", "/privacy", "/term&condition", "/blog"].includes(
      pathname
    ) || pathname.startsWith("/blog/");

  const token = window.localStorage.getItem("token");
  console.log("user token", token);

  // const PrivateRoute = ({ children }) => {
  //   return token ? children : <Navigate to="/signup" replace />;
  // };

  const PrivateRoute = ({ children }) => {
    // const token = window.localStorage.getItem("token");
    // console.log("Token in private route", token);

    return token ? children : <Navigate to="/signin" replace />;
  };

  useEffect(() => {
    // Check token and redirect accordingly
    if (pathname === "/") {
      if (token) {
        navigate("/uploadFile"); // Redirect to upload page if authorized
      } else {
        navigate("/signin"); // Redirect to sign-in page if not authorized
      }
    }
  }, [pathname, token, navigate]);


  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Homepage />} />

        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/signin" element={<Signin />} />

        <Route path="/term&condition" element={<TermAndCondition />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/menu" element={<Menu />} />

        <Route path="/payment-failure/" element={<PaymentFailure />} />
        <Route path="/request-password-reset" element={<ForgotPassword />} />

        <Route path="/folder/:folderName" element={<FolderContents />} />
        {/* <Route path="/test" element={<Invoice_Details />} /> */}

        <Route
          path="/reset-password/:uid/:token"
          element={<CreatePassword />}
        />
        <Route path="/complete-account" element={<CompleteAccount />} />
        <Route path="/otp" element={<OtpPage />} />

        <Route
          path="/payment-success/"
          element={
            <PrivateRoute>
              <PaymentSuccess />
            </PrivateRoute>
            // <PaymentSuccess/>
          }
        />

        <Route
          path="/uploadFile"
          element={
            <PrivateRoute>
              <Layout>
                <UploadFilePage />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/Invoices"
          element={
            <PrivateRoute>
              <Layout>
                <Invoices />
              </Layout>
            </PrivateRoute>
          }
        />


        <Route
          path="/ai-copilot"
          element={
            <PrivateRoute>
              <Layout>
                <AIcopilot token={token} />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/CopilotChatScreen"
          element={
            <PrivateRoute>
              <Layout>
                <CopilotChat />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/Invoices/Invoice_Details"
          element={
            <PrivateRoute>
              <Layout>
                <Invoice_Details />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <Pricing />
            </PrivateRoute>
          }
        />

        <Route
          path="/Contract/FolderChat"
          element={
            <PrivateRoute>
              <Layout open={false}>
                <FolderChat />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/Invoices/Invoice_chat"
          element={
            <PrivateRoute>
              <Layout open={false}>
                <Invoice_chat />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/folder"
          element={
            <PrivateRoute>
              <Layout>
                <FolderContents />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/Contract/contract-details"
          element={
            <PrivateRoute>
              <Layout>
                <ContractDetails />
              </Layout>
            </PrivateRoute>
          }
        />


        <Route
          path="/Contract/contractChat"
          element={
            <PrivateRoute>
              <Layout open={false}>
                <ContractChat />
              </Layout>
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/Contract/contractChat"
          element={
            <PrivateRoute>
              <Layout>
                <ContractChat />
              </Layout>
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/setting"
          element={
            <PrivateRoute>
              <Layout>
                <Setting />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <Layout open={false}>
                <Chat />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/chat/:id"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />

        <Route path="/folder-contents" element={<FolderContents />} />
        <Route
          path="/folder"
          element={
            <PrivateRoute>
              <FolderContents />
            </PrivateRoute>
          }
        />

        <Route
          path="/uploadFile"
          element={
            <PrivateRoute>
              <Layout>
                <UploadFilePage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/UploadYoutubeUrl"
          element={
            <PrivateRoute>
              <Layout>
                <UploadYoutubeUrl />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/youtube-summary"
          element={
            <PrivateRoute>
              <Layout>
                <YoutubeSummary />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/upload-excel"
          element={
            <PrivateRoute>
              <Layout>
                <UploadExcel />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/excel-chat"
          element={
            <PrivateRoute>
              <ExcelChat />
            </PrivateRoute>
          }
        />

        <Route
          path="/excel-chat/:id"
          element={
            <PrivateRoute>
              <ExcelChat />
            </PrivateRoute>
          }
        />

        <Route
          path="/test"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        <Route
          path="/test/c"
          element={
            <PrivateRoute>
              <Layout>
                <ChatTest />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/contract"
          element={
            <PrivateRoute>
              <Layout>
                <Contract />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {/* {isFooterVisible && <Footer />} */}
    </React.Fragment>
  );
};

export default MainRoutes;
