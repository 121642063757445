import React, { useState } from "react";
import "./explorePromptModal.css";

// 1. Data Structure for All Sections
const promptSections = [
  {
    name: "Featured",
    icon: "/featured-icon.svg",
    shortPrompts: ["Summarize Document", "Find Keyword", "Extract Key data"],
    fullPrompts: ["Summarize Document", "Find Keyword", "Extract Key data"],
  },
  {
    name: "Documents",
    icon: "/document-icon.svg",
    shortPrompts: ["Summarize Key Takeaways", "Locate Keywords"],
    fullPrompts: ["Summarize Key Takeaways", "Locate Keywords"],
  },
  {
    name: "Contracts",
    icon: "/contract-icon.svg",
    shortPrompts: [
      "Extract the starting date of the contract",
      "View Termination Conditions",
      "Parties Involved",
    ],
    fullPrompts: [
      "Extract the starting date of the contract",
      "View Termination Conditions",
      "Parties Involved",
    ],
  },
  {
    name: "Invoices",
    icon: "/invoices-icon.svg",
    shortPrompts: ["Total Due Amount", "Issuer of Invoice", "Payment due date"],
    fullPrompts: ["Total Due Amount", "Issuer of Invoice", "Extract Payment due date from the Invoice"],
  },
  {
    name: "Spreadsheets",
    icon: "/spreadsheet-icon.svg",
    shortPrompts: ["Analyze trends in Data", "Sales breakdown by region", "Summarize Monthly Expense"],
    fullPrompts: ["Analyze trends in Data", "Sales breakdown by region", "Summarize Monthly Expense"],
  },
  {
    name: "Resumes",
    icon: "/resume-icon.svg",
    shortPrompts: [
      "Extract Key Skills",
      "Summarize work Experience",
      "Extract Education Details",
    ],
    fullPrompts: [
      "Extract Key Skills",
      "Summarize work Experience",
      "Extract Education Details",
      "Analyze Career Progression",
      "Identify Missing Details",
    ],
  },
];

/**
 * ExplorePromptModal Component
 *
 * @param {boolean}   isOpen          - Controls whether the modal is displayed
 * @param {function}  onClose         - Callback to close the modal
 * @param {function}  onSelectPrompt  - Callback when a prompt is selected (passed from parent)
 */
const ExplorePromptModal = ({ isOpen, onClose, onSelectPrompt }) => {
  // Tracks which section is currently expanded (null means no section expanded).
  const [selectedSection, setSelectedSection] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Close the modal
  const handleClose = () => {
    onClose();
  };

  // If the modal is closed, don't render anything
  if (!isOpen) return null;

  /**
   * 1. Convert search query to lowercase once for consistent matching.
   * 2. Filter out sections that:
   *    - Don't have the query in the section name, AND
   *    - Don't have the query in any shortPrompts or fullPrompts.
   * 3. Only keep prompts that match within each section.
   */
  const lowerSearchQuery = searchQuery.toLowerCase();

  const filteredSections = promptSections
    .map((section) => {
      // Check if the section name contains the search query
      const isSectionNameMatch = section.name.toLowerCase().includes(lowerSearchQuery);

      // Filter prompts
      const filteredShortPrompts = section.shortPrompts.filter((prompt) =>
        prompt.toLowerCase().includes(lowerSearchQuery)
      );
      const filteredFullPrompts = section.fullPrompts.filter((prompt) =>
        prompt.toLowerCase().includes(lowerSearchQuery)
      );

      // If section name doesn't match and no prompts match, exclude the entire section
      if (
        !isSectionNameMatch &&
        filteredShortPrompts.length === 0 &&
        filteredFullPrompts.length === 0
      ) {
        return null; // Return null to indicate we exclude this section
      }

      // Otherwise, return the section with only matched prompts
      return {
        ...section,
        shortPrompts: filteredShortPrompts,
        fullPrompts: filteredFullPrompts,
      };
    })
    // Filter out any null sections
    .filter((section) => section !== null);

  // Identify which section is expanded (if any) from the filtered list
  const expandedSection = filteredSections.find(
    (section) => section.name === selectedSection
  );

  return (
    <div className="prompt-modal-overlay-details">
      <div className="prompt-modal-content-details">
        {/* Close button */}
        <button
          className="close-button"
          onClick={handleClose}
          style={{ marginRight: "30px", fontSize: "22px" }}
        >
          x
        </button>

        {/* Heading */}
        <h1 className="text-center text-2xl font-bold mb-3">Prompt Library</h1>

        {/* Search box */}
        <div className="promptSearchBox text-center items-center">
          <img src="/search.svg" alt="search icon" />
          <input
            type="text"
            placeholder="Search prompts by task or document type..."
            className="prompt-input-space"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* If a section is selected, display its full prompts, otherwise show all sections */}
        {selectedSection && expandedSection ? (
          // ------------- EXPANDED SECTION -------------
          <div>
            {/* Icon + Section Name */}
            <div className="flex items-center mb-[20px] mx-[25px]">
              <img
                src={expandedSection.icon}
                alt={`${expandedSection.name} icon`}
              />
              <h2 className="text-lg font-semibold ml-[12px] text-[#C95EBE]">
                {expandedSection.name}
              </h2>
            </div>

            {/* Full Prompts for this section */}
            <div className="promptScrollSection">
              {expandedSection.fullPrompts.map((item, index) => (
                <div
                  key={index}
                  className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px] mb-[15px]"
                >
                  <button
                    onClick={() => {
                      onSelectPrompt(item); // <-- Trigger callback with prompt text
                      onClose();            // <-- Close modal if desired
                    }}
                    className={
                      expandedSection.name === "Featured"
                        ? "flex linear-grad-btn h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                        : "flex gray-brdr h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                    }
                    style={{ background: "white" }}
                  >
                    {item}
                  </button>
                </div>
              ))}
            </div>

            {/* "Less" button to go back */}
            <div className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px] mt-5">
              <button
                onClick={() => setSelectedSection(null)}
                className="flex items-center gap-1 text-bold"
              >
                <img
                  src="/see-less-icon.svg"
                  alt="See less icon"
                  style={{ transform: "rotate(180deg)" }}
                />
                <span className="font-[Outfit] text-[14px] font-medium text-[#C95EBE]">
                  Less
                </span>
              </button>
            </div>
          </div>
        ) : (
          // ------------- MAIN SCREEN WITH FILTERED SECTIONS -------------
          <div className="promptScrollSection">
            {filteredSections.map((section) => (
              <div key={section.name} className="mb-[35px] mx-[25px]">
                {/* Icon + Section Name */}
                <div className="flex items-center mb-[20px]">
                  <img src={section.icon} alt={`${section.name} icon`} />
                  <span className="text-lg font-semibold ml-[12px] text-[#C95EBE]">
                    {section.name}
                  </span>
                </div>

                {/* Show matched shortPrompts */}
                {section.shortPrompts.map((prompt, index) => (
                  <div
                    key={index}
                    className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px]"
                  >
                    <button
                      onClick={() => {
                        onSelectPrompt(prompt); // <-- Trigger callback with prompt text
                        onClose();             // <-- Close modal if desired
                      }}
                      className={
                        section.name === "Featured"
                          ? "flex linear-grad-btn h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                          : "flex gray-brdr h-[20px] px-[20px] pt-[15px] pb-[15px] justify-center items-center rounded-[28px] border-none text-center font-[Outfit] text-[12px] font-medium text-dark cursor-pointer overflow-hidden"
                      }
                      style={{ background: "white" }}
                    >
                      {prompt}
                    </button>
                  </div>
                ))}

                {/* "More" button - expands to show fullPrompts */}
                <div className="relative inline-block rounded-[28px] p-[0.5px] mr-[12px]">
                  <button
                    onClick={() => setSelectedSection(section.name)}
                    className="flex items-center gap-1 text-bold"
                  >
                    <span className="font-[Outfit] text-[14px] font-medium text-[#C95EBE]">
                      More
                    </span>
                    <img src="/see-more-icon.svg" alt="See more icon" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplorePromptModal;
