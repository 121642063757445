import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Example icons: update these imports if needed
import arrowForward2 from "../../Images/arrow_forward-2.svg";
import ChatHistory from "./ChatHistory.jsx";

// Your custom CSS
import "../AI_Copilot/PromptChat.css";

const PromptChat = ({
  documentKey = "",
  documentUrl = "",
  documentType = "",
  prompt = "",
  finalResponse = "",
  generatedQuestions = [],
  chatId,
  isMenuOpen,
  /** The text from parent you want automatically sent as a user message on mount **/
  chatInput,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const sentRef = useRef(false);

  // =============== STATE ===============
  const [chatHistory, setChatHistory] = useState([]);
  const [generatedPromptQuestions, setGeneratedPromptQuestions] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [clientMessage, setClientMessage] = useState("");

  // For streaming partial text
  const [streamingText, setStreamingText] = useState("");

  // Additional states if you need them
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  // Redux or other selectors
  const error = useSelector((state) => state.pdfChat?.error);
  const { runId } = useSelector((state) => state.pdfChat);

  // If parent used react-router state to pass data
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl, docType } = location.state || {};

  const [pdfKey, setPdfKey] = useState(dynamicPdfKey || "");
  const [pdfUrl, setPdfUrl] = useState("");
  const [chatResponse, setChatResponse] = useState(finalResponse);

  // =============== EFFECTS ===============
  useEffect(() => {
    setChatResponse(finalResponse);
  }, [finalResponse]);

  // If parent passed a dynamic PDF URL
  useEffect(() => {
    if (dynamicPdfUrl) {
      setPdfUrl(dynamicPdfUrl);
    }
  }, [dynamicPdfUrl]);

  // Auto-scroll to bottom on chatHistory update
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory]);

  // If you have any dynamic recommended questions, use them
  const streamDummyMessages = useCallback((questions) => {
    const maxToShow = 2;
    const limited = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(limited.length).fill(""));

    limited.forEach((question, idx) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updated = [...prev];
          updated[idx] = question.slice(0, charIndex + 1);
          return updated;
        });
        charIndex++;
        if (charIndex >= question.length) clearInterval(interval);
      }, 30);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(generatedQuestions) && generatedQuestions.length > 0) {
      streamDummyMessages(generatedQuestions);
    }
  }, [generatedQuestions, streamDummyMessages]);

  useEffect(() => {
    if (generatedPromptQuestions.length > 0) {
      // console.log("Updated Generated Questions (Child):", generatedPromptQuestions);
      streamDummyMessages(generatedPromptQuestions);
    }
  }, [generatedPromptQuestions, streamDummyMessages]);

  // OPTIONAL: If you want to automatically send parent's chatInput once on mount
  useEffect(() => {
    if (chatInput?.trim() && !sentRef.current) {
      sentRef.current = true;
      handleSendPrompt(chatInput.trim());
    }
  }, [chatInput]);

  // =============== NEW CHAT (OPTIONAL) ===============
  const [newChatId, setNewChatId] = useState(null);
  const initiateNewChat = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.chat_id) {
        setNewChatId(response.data.chat_id);
        // console.log("New chat ID:", response.data.chat_id);
      } else {
        throw new Error("Chat ID not received");
      }
    } catch (error) {
      console.error("Error initiating chat:", error);
      toast.error("Failed to start a new chat. Please try again.");
    }
  };

  const handleNewChat = () => {
    setChatHistory([]);
    setGeneratedPromptQuestions([]);
    setClientMessage("");
    initiateNewChat();
  };

  // =============== MAIN SEND PROMPT (WS) ===============
  const handleSendPrompt = (prompt) => {
    // console.log("Sending prompt:", prompt);

    // 1) Add user message to chatHistory
    const userMsgId = Date.now();
    const userMessage = {
      id: userMsgId,
      sender: "user",
      text: prompt,
      final: true,
    };
    setChatHistory((prev) => [...prev, userMessage]);

    // 2) Create a "placeholder" bot message for partial streaming
    const botMsgId = Date.now() + 1;
    const botPlaceholder = {
      id: botMsgId,
      sender: "bot",
      text: "",
      final: false, // not final yet
    };
    setChatHistory((prev) => [...prev, botPlaceholder]);

    setIsLoadingResponse(true);
    setGeneratedPromptQuestions([]);

    // 3) Open WebSocket
    try {
      const ws = new WebSocket(
        `wss://wa-docusensa-backend-staging-bhbmcve5e7atgwcs.centralindia-01.azurewebsites.net/ws/prompt-library-answer/${chatId}/?token=${localStorage.getItem("token")}`
      );

      ws.onopen = () => {
        console.log("WebSocket: connected");
        // Send the prompt
        ws.send(
          JSON.stringify({
            document_key: documentKey,
            prompt: prompt,
          })
        );
      };

      ws.onmessage = (event) => {
        // console.log("WebSocket onmessage:", event.data);
        try {
          const data = JSON.parse(event.data);

          // 4) If progress => append chunk to this placeholder bot message
          if (data.type === "progress" && data.chunk) {
            setChatHistory((prev) =>
              prev.map((msg) => {
                if (msg.id === botMsgId && !msg.final) {
                  // append the chunk
                  return {
                    ...msg,
                    text: msg.text + data.chunk,
                  };
                }
                return msg;
              })
            );
          }

          // 5) If final => mark placeholder as final
          if (data.type === "final") {
            const finalChunk = data.chunk || "";
            setChatHistory((prev) =>
              prev.map((msg) => {
                if (msg.id === botMsgId && !msg.final) {
                  return {
                    ...msg,
                    text: msg.text + finalChunk,
                    final: true,
                  };
                }
                return msg;
              })
            );

            setIsLoadingResponse(false);

            // If generated questions
            if (data.data && data.data.generated_questions) {
              const questionsArray = data.data.generated_questions
                .split(";")
                .map((q) => q.trim())
                .filter(Boolean)
                .slice(0, 2);
              setGeneratedPromptQuestions(questionsArray);
            }
            ws.close();
          }
        } catch (err) {
          console.error("Error parsing WS data:", err);
        }
      };

      ws.onerror = (err) => {
        console.error("WebSocket error:", err);
        toast.error("WebSocket error occurred. Please try again.");
        setIsLoadingResponse(false);
      };

      ws.onclose = () => {
        console.log("WebSocket closed");
        setIsLoadingResponse(false);
      };
    } catch (error) {
      console.error("WebSocket init error:", error);
      toast.error("Failed to connect to WebSocket. Please try again.");
      setIsLoadingResponse(false);
    }
  };

  // =============== SEND TEXTAREA MESSAGE ===============
  const handleSend = () => {
    if (!clientMessage.trim()) {
      toast.error("Please enter a prompt!");
      return;
    }
    handleSendPrompt(clientMessage.trim());
    setClientMessage("");
    setTypedDummyMessages([]); // Clear recommended
  };

  // =============== FORMAT TEXT (OPTIONAL) ===============
  const formatText = useCallback((input) => {
    if (typeof input !== "string" || !input.trim()) return "";
    return input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
  }, []);

  // =============== VIEW DETAILS / CHAT WITH DOC ===============
  const handleViewDetails = (documentKey, documentType) => {
    if (!documentKey) {
      console.error("File key is missing!");
      toast.error("File key is not available.");
      return;
    }
    if (documentType === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey: documentKey },
      });
    } else if (documentType === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: documentKey },
      });
    } else {
      console.warn("Unsupported document type:", documentType);
    }
  };

  const handleChatWithDoc = () => {
    if (!documentKey || !documentUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }
    navigate("/chat", { state: { pdfUrl: documentUrl, pdfKey: documentKey } });
  };

  // =============== THUMBS UP/DOWN & FEEDBACK ===============
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: !prev[messageId] }));
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: false }));
    if (!thumbsUpClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback();
    }
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({ ...prev, [messageId]: !prev[messageId] }));
    setThumbsUpClicked((prev) => ({ ...prev, [messageId]: false }));
    if (!thumbsDownClicked[messageId]) {
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const submitFeedback = async () => {
    try {
      if (!messageIdToFeedback || !runIdToFeedback || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return;
      }
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: messageIdToFeedback,
          run_id: runIdToFeedback,
          feedback_type: selectedFeedbackType,
          user_feedback: feedbackMessage || "",
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");
    setFeedbackMessage(combinedFeedback);

    try {
      await submitFeedback();
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  // =============== CHAT HISTORY SIDEBAR (OPTIONAL) ===============
  const [isOpen, setIsOpen] = useState(false);
  const toggleChatHistory = () => {
    setIsOpen(!isOpen);
  };

  // handling see-more icon (dropdown)
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (messageId) => {
    setOpenDropdownId((prev) => (prev === messageId ? null : messageId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // console.log("Updated chat history:", chatHistory);
  }, [chatHistory]);

  // =============== RENDER ===============
  return (
    <div className="AI-CP-PL-full-container">
      <div className="AI-CP-PL-docChatContainer">
        {/* Left Section: Chat History (optional) */}
        <div className={`${isMenuOpen ? "ml-20" : "ml-0"} AI-CP-PL-ChatHistoryContainer flex gap-4`}>
          <ChatHistory isOpen={isOpen} />
        </div>

        {/* Main Chat Content */}
        <div className="AI-CP-PL-docChatContent">
          <div className="AI-CP-PL-message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => {
              const isBot = message.sender === "bot";
              const isFinal = message.final;
              const isStreaming = isBot && !isFinal;

              // e.g., If it's the first bot message
              const isFirstBotMessage =
                isBot && chatHistory.findIndex((msg) => msg.sender === "bot") === index;

              return (
                <div
                  key={message.id || index}
                  className={`mb-2 ${isBot
                      ? "Ai-CP-bot-message-container w-full flex items-start"
                      : "w-full flex justify-end"
                    }`}
                >
                  {/* BOT ICON */}
                  {isBot && (
                    <div className="bot-icon-container flex-shrink-0 mr-2">
                      <img
                        src="/bot-icon.svg"
                        alt="Bot Icon"
                        className="bot-icon h-6 w-6 rounded-full"
                      />
                    </div>
                  )}

                  {/* MESSAGE BUBBLE */}
                  <div className="flex flex-col">
                    {/* If streaming, optionally show "thinking" dots */}
                    {/* {isStreaming && (
                      <div className="thinking-indicator mb-2">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    )} */}

                    {/* The message text (partial or final) */}
                    {message.sender === "user" ? (
                      <span className="user-message-style">{message.text}</span>
                    ) : (
                      <span
                        className="AI-CP-bot-message-content"
                        dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
                      />
                    )}

                    {/* Reaction Buttons if it's a final bot message */}
                    {isBot && isFinal && (
                      <div className="AI-CP-reactionContainer">
                        <div className="Ai-CP-reactions flex gap-2 text-gray-500 mt-2">
                          {/* Show "See More" only if not the first bot message */}
                          {!isFirstBotMessage && (
                            <span className="see-more-reaction-icon cursor-pointer" ref={dropdownRef}>
                              <img
                                src="/more-icon.svg"
                                alt="more icon"
                                onClick={() => toggleDropdown(message.id)}
                              />
                              {openDropdownId === message.id && (
                                <div className="dropdown-menu">
                                  <button
                                    onClick={() => handleViewDetails(documentKey, documentType)}
                                  >
                                    <img src="/eye-icon.svg" alt="eye icon" />
                                    <span>View More</span>
                                  </button>
                                  <button onClick={handleChatWithDoc}>
                                    <img src="chatButton-icon.svg" alt="chat-button" />
                                    <span>Chat</span>
                                  </button>
                                </div>
                              )}
                            </span>
                          )}

                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsUpClick(message.id)}
                          >
                            <img
                              src={
                                thumbsUpClicked[message.id]
                                  ? "/thums-up-filled.svg"
                                  : "/thums-up.svg"
                              }
                              alt="Thumbs Up"
                            />
                          </span>
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleThumbsDownClick(message.id)}
                          >
                            <img
                              src={
                                thumbsDownClicked[message.id]
                                  ? "/thums-down-filled.svg"
                                  : "/thums-down.svg"
                              }
                              alt="Thumbs Down"
                            />
                          </span>
                          <span
                            className="reaction-icon cursor-pointer"
                            onClick={() => handleCopy(message.id, message.text)}
                          >
                            <img
                              src={
                                copiedMessageId === message.id ? "/copy-clicked.svg" : "/copy.svg"
                              }
                              alt="Copy"
                            />
                          </span>
                        </div>

                        {/* Example: extra buttons if it's first bot message */}
                        {isFirstBotMessage && (
                          <div className="AI-CP-reaction-buttons mt-2">
                            {documentType !== "normal_document" && (
                              <button
                                className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px]"
                                onClick={() => handleViewDetails(documentKey, documentType)}
                              >
                                View More
                              </button>
                            )}
                            <button
                              className="w-[99px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[35px] py-[6px]"
                              onClick={handleChatWithDoc}
                            >
                              Chat
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {/* Error if any */}
            {error && (
              <div className="AI-CP-bot-message-container w-full flex items-start mb-4">
                <div className="AI-CP-bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>

          {/* Recommended Questions */}
          <div className="AI-CP-PL-dummy-container">
            {generatedPromptQuestions?.map((question, idx) => (
              <div
                key={idx}
                className="AI-CP-PL-dummy-message-box border border-purple-400 rounded-lg p-4 mt-4 flex items-center cursor-pointer"
                onClick={() => setClientMessage(question.trim())}
              >
                <span className="icon mr-2">
                  <img src="/dummy-prompt-icon.svg" alt="Prompt Icon" />
                </span>
                <p className="text-sm">{question.trim() || "..."}</p>
              </div>
            ))}
          </div>

          {/* Input Area */}
          <div className="AI-CP-input-container-holder">
            <textarea
              placeholder="How can I help? Type your Question or choose a prompt"
              className="AI-CP-input-field-holder"
              value={clientMessage}
              onChange={(e) => {
                setClientMessage(e.target.value);
                if (textareaRef.current) {
                  textareaRef.current.style.height = "auto";
                  textareaRef.current.style.height = `${Math.min(
                    e.target.scrollHeight,
                    88
                  )}px`;
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (clientMessage.trim()) {
                    handleSend();
                  }
                }
              }}
              ref={textareaRef}
            />
            <button
              className="AI-CP-send-button-holder"
              onClick={() => {
                if (clientMessage.trim()) {
                  handleSend();
                }
              }}
            >
              <img src="/send.svg" alt="Send" className="send-icon" style={{ width: "24px", height: "24px" }} />
            </button>
          </div>
        </div>
      </div>

      {/* Thumbs Down Popup */}
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button className="close-button-thumsdown" onClick={handleClosePopup}>
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          factuallyIncorrect: e.target.checked,
                        }))
                      }
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.refused}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          refused: e.target.checked,
                        }))
                      }
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={(e) =>
                        setSelectedCheckboxes((prev) => ({
                          ...prev,
                          notFollowingInstructions: e.target.checked,
                        }))
                      }
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${!feedbackMessage.trim() &&
                      !Object.values(selectedCheckboxes).some((x) => x)
                      ? "disabled-button-thumsdown"
                      : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={
                    !feedbackMessage.trim() &&
                    !Object.values(selectedCheckboxes).some((x) => x)
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptChat;
