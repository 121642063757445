import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "../Features/chatSlice";
import chatContractReducer from '../Features/chatContractSlice';
import pdfChatReducer from '../Features/pdfChatSlice';
import invoiceChatReducer from '../Features/invoiceChatSlice';
import feedbackReducer from '../Features/sendFeedback';



const store = configureStore({
  reducer: {
    chat: chatReducer,
    chatContract: chatContractReducer,
    pdfChat: pdfChatReducer,
    feedback: feedbackReducer,
    invoiceChat: invoiceChatReducer,

  },
});

export default store;
