import React, { useState } from "react";
import axios from "axios";
import "./LineItems.css";

const LineItems = ({ invoiceKey, initialLineItems, updateLineItems }) => {
  const [lineItems, setLineItems] = useState(initialLineItems || []);
  const [isVisible, setIsVisible] = useState(true);
  const [isEditing, setIsEditing] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [newItem, setNewItem] = useState({
    "Item Name": "",
    Quantity: 0,
    Rate: 0,
    Total: 0,
  });
  const [isAdding, setIsAdding] = useState(false);

  const token = localStorage.getItem("token");
  const [isLineItemsOpen, setIsLineItemsOpen] = useState(false);
  const handleEditClick = (index) => {
    setIsEditing(index);
    setEditedItem({ ...lineItems[index] });
  };

  const handleSaveEdit = async (index) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/invoice/edit-item/${invoiceKey}/`,
        { index, item: editedItem },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setLineItems(response.data.line_items);
        updateLineItems(response.data.line_items);
        setIsEditing(null);
      } else {
        alert(response.data.msg || "Failed to edit the item.");
      }
    } catch (error) {
      console.error(
        "Error editing item:",
        error.response?.data || error.message
      );
      alert("An error occurred while editing the item.");
    }
  };

  const handleDeleteItem = async (index) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/invoice/delete-item/${invoiceKey}/`,
        { index },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setLineItems(response.data.line_items);
        updateLineItems(response.data.line_items);
      } else {
        alert(response.data.msg || "Failed to delete the item.");
      }
    } catch (error) {
      console.error(
        "Error deleting item:",
        error.response?.data || error.message
      );
      alert("An error occurred while deleting the item.");
    }
  };

  const handleAddNewRow = () => {
    setIsAdding(true);
    setNewItem({
      "Item Name": "",
      Quantity: 0,
      Rate: 0,
      Total: 0,
    });
  };

  const handleSaveNewRow = async () => {
    if (!newItem["Item Name"] || newItem.Quantity <= 0 || newItem.Rate <= 0) {
      alert("Please fill out all fields for the new item.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/invoice/add-item/${invoiceKey}/`,
        { item: newItem },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setLineItems(response.data.line_items);
        updateLineItems(response.data.line_items);
        setIsAdding(false);
      } else {
        alert(response.data.msg || "Failed to add the new item.");
      }
    } catch (error) {
      console.error(
        "Error adding item:",
        error.response?.data || error.message
      );
      alert("An error occurred while adding the new item.");
    }
  };

  const handleCancelNewRow = () => {
    setIsAdding(false);
    setNewItem({
      "Item Name": "",
      Quantity: 0,
      Rate: 0,
      Total: 0,
    });
  };

  return (
    <section className="line-items-wrapper">
      <div className="payment-informations">
        <img src="/invoice-paylogo.svg" alt="Key Dates Icon" />
        <h3>Line Items</h3>
      </div>
      <table>
        <thead>
          <tr>
            <th className="table-head-lineitems">Item Name</th>
            <th className="table-head-lineitems">Quantity</th>
            <th className="table-head-lineitems">Rate</th>
            <th className="table-head-lineitems">Total</th>
            <th className="table-head-lineitems">Actions</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.length > 0 ? (
            lineItems.map((item, index) => (
              <tr key={index}>
                <td className="table-data-lineitems">
                  {isEditing === index ? (
                    <input
                      type="text"
                      className="edit-input-lineitems"
                      value={editedItem["Item Name"]}
                      onChange={(e) =>
                        setEditedItem({
                          ...editedItem,
                          "Item Name": e.target.value,
                        })
                      }
                    />
                  ) : (
                    item["Item Name"] || "N/A"
                  )}
                </td>
                <td>
                  {isEditing === index ? (
                    <input
                      type="number"
                      className="edit-input-lineitems"
                      value={editedItem.Quantity}
                      onChange={(e) =>
                        setEditedItem({
                          ...editedItem,
                          Quantity: Number(e.target.value),
                          Total: Number(e.target.value) * editedItem.Rate,
                        })
                      }
                    />
                  ) : (
                    item.Quantity || 0
                  )}
                </td>
                <td>
                  {isEditing === index ? (
                    <input
                      type="number"
                      className="edit-input-lineitems"
                      value={editedItem.Rate}
                      onChange={(e) =>
                        setEditedItem({
                          ...editedItem,
                          Rate: Number(e.target.value),
                          Total: editedItem.Quantity * Number(e.target.value),
                        })
                      }
                    />
                  ) : (
                    item.Rate || 0
                  )}
                </td>
                <td>
                  {isEditing === index ? editedItem.Total : item.Total || 0}
                </td>
                <td>
                  {isEditing === index ? (
                    <button
                      onClick={() => handleSaveEdit(index)}
                      className="done-linesitens"
                    >
                      Save
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditClick(index)}
                        className="focus:outline-none"
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                      >
                        <img
                          src="/edit-icon.svg"
                          alt="Edit"
                          className="edit-lineitems"
                        />
                      </button>
                      <button
                        onClick={() => handleDeleteItem(index)}
                        className="focus:outline-none"
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                      >
                        <img
                          src="/delete-icon-red.svg"
                          alt="Delete"
                          className="delete-lineitems"
                        />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center" }}>
                No line items available.
              </td>
            </tr>
          )}

          {isAdding && (
            <tr>
              <td>
                <input
                  type="text"
                  className="edit-input-lineitems"
                  value={newItem["Item Name"]}
                  onChange={(e) =>
                    setNewItem({ ...newItem, "Item Name": e.target.value })
                  }
                  placeholder="Enter your item name"
                />
              </td>
              <td>
                <input
                  type="number"
                  className="edit-input-lineitems"
                  value={newItem.Quantity || ""}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      Quantity: Number(e.target.value),
                      Total: Number(e.target.value) * newItem.Rate,
                    })
                  }
                  placeholder="Enter quantity"
                />
              </td>
              <td>
                <input
                  type="number"
                  className="edit-input-lineitems"
                  value={newItem.Rate || ""}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      Rate: Number(e.target.value),
                      Total: newItem.Quantity * Number(e.target.value),
                    })
                  }
                  placeholder="Enter rate"
                />
              </td>
              <td>{newItem.Total || 0}</td>
              <td>
                <button onClick={handleSaveNewRow} className="done-linesitens">
                  Done
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isAdding && (
        <button className="add-item-invoice" onClick={handleAddNewRow}>
          Add Item <span>+</span>
        </button>
      )}
    </section>
  );
};

export default LineItems;
