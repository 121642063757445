// ChatHistory.js
import React from "react";
import "./ChatHistory.css";

const ChatHistory = ({ isOpen }) => {
    if (!isOpen) return null; // Only render if isOpen is true


    

    return (
        <div className="chat-historyDiv">
            {/* Search box */}
            <div className="ChatHistorySearchBox text-center items-center">
                <img src="/search.svg" alt="search icon" />
                <input
                    type="text"
                    placeholder="Search "
                    className="ChatHistorySearchBox-input"

                />
            </div>
            <div className="ChatHIstory-EmptyContent">
                <h1 className="no-chat-history">
                    No chat history yet!
                </h1>
                <p className="newChatHistory-prompt">
                    Start a conversation by choosing a prompt or typing a question
                </p>

                <img src="ChatHistory-bot-icon.svg" alt="bot icon" className="mt-4" />
            </div>
            <button className="start-newChat-button">
                <img src="/newChat-history-icon.svg" alt="New Chat Icon" class="" />
                <span className="w-[63px] h-[18px] font-outfit font-semibold text-[14px] leading-[17.64px]">
                    New Chat
                </span>
            </button>


        </div>
    );
};

export default ChatHistory;
