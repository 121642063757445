import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPdfChat,
  resetPdfChatState,
  updatePartialAnswer,
} from "../../Features/pdfChatSlice";
import { Header } from "../../components/Header/Header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import arrowForward2 from "../../Images/arrow_forward-2.svg";
import axios from "axios";
import "./CopilotChat.css";
import "../Contract/contractChat.css";

const CopilotChat = (isMenuOpen) => {

  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // ------------------ Local State ------------------
  const [inputMessage, setInputMessage] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [isSending, setIsSending] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  // Popups & feedback
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);

  // Chat control
  const [clientMessage, setClientMessage] = useState("");
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);

  // Additional doc states
  const [contractKey, setContractKey] = useState(null);
  const [contractUrl, setContractUrl] = useState("");
  const [file, setFile] = useState(null);
  const [chatInput, setChatInput] = useState("");
  const [chatId, setChatId] = useState(null);

  // Redux states
  const status = useSelector((state) => state.chat.status);
  const partialAnswer = useSelector((state) => state.pdfChat?.partialAnswer || "");
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");

  const [generatedQuestions, setGeneratedQuestions] = useState([]);

  const error = useSelector((state) => state.pdfChat?.error);
  const { botMessageId, runId } = useSelector(
    (state) => state.pdfChat
  );

  // From location/state
  const { invoiceKey } = location.state || {};
  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl, docType: doc_type } =
    location.state || {};
  const { docType } = location.state || {};
  // For demonstration, we set these from location.state
  const [pdfKey, setPdfKey] = useState(dynamicPdfKey || " ");
  const [pdfUrl, setPdfUrl] = useState("");

  // For token storage
  const [token, setToken] = useState(null);

  // ---------- Retrieve Token from localStorage ----------
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const removePlaceholder = () => {
    if (chatHistory && chatHistory.length > 0 && chatHistory[0]?.text?.includes("Summary is loading")) {
      setChatHistory((prev) => prev.filter((msg) => msg.text && !msg.text.includes("Summary is loading")));
    }
  };

  useEffect(() => {
    removePlaceholder();
  }, [chatHistory]);






  // ------------------------------------------------------
  // Function to format bot text with HTML
  // ------------------------------------------------------
  const formatText = useCallback((input) => {
    if (typeof input !== "string" || !input.trim()) {
      console.log("Received pdfKey:", pdfKey);
      console.log("Received pdfUrl:", pdfUrl);
      return "";
    }
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");
    return formattedText;
  }, []);

  useEffect(() => {
    // Set the pdfUrl state dynamically if needed
    if (dynamicPdfUrl) {
      setPdfUrl(dynamicPdfUrl);
    }
  }, [dynamicPdfUrl]);

  console.log("PDF Key:", pdfKey);
  console.log("PDF URL:", pdfUrl);
  console.log("Location state:", location.state);


  // ------------------------------------------------------
  //  WebSocket Initialization
  // ------------------------------------------------------
  useEffect(() => {
    if (!pdfKey || !token || socket) return;
  
    const wsUrl = `wss://wa-docusensa-backend-staging-bhbmcve5e7atgwcs.centralindia-01.azurewebsites.net/ws/qdrant-chat-doc/${pdfKey}/?token=${token}`;
    console.log("Attempting WebSocket connection to:", wsUrl);
  
    const ws = new WebSocket(wsUrl);
  
    ws.onopen = () => {
      console.log("WebSocket connection established:", wsUrl);
      setIsSummaryLoading(false);
      setSocket(ws);
  
      // Check if the message was already sent to avoid duplication
      const initialClientMessage = "Summarize the given document";
  
      setChatHistory((prev) => {
        const messageExists = prev.some(
          (msg) => msg.sender === "user" && msg.text === initialClientMessage
        );
  
        if (!messageExists) {
          // Add message to chat history if not already present
          return [
            ...prev,
            { id: Date.now(), sender: "user", text: initialClientMessage },
          ];
        }
        return prev;
      });
  
      // Send the message only if not sent before
      if (!chatHistory.some((msg) => msg.text === initialClientMessage)) {
        const initialMessagePayload = JSON.stringify({ client_message: initialClientMessage });
        ws.send(initialMessagePayload);
        console.log("Initial message sent:", initialMessagePayload);
  
        setIsAwaitingResponse(true);
        setIsThinking(true);
      }
    };
  
    ws.onmessage = (event) => {
      try {
        if (!event.data) {
          console.error("Received empty message");
          return;
        }
    
        const message = JSON.parse(event.data);
    
        if (!message || typeof message !== "object") {
          console.error("Invalid message format:", event.data);
          return;
        }
    
        handleWebSocketMessage(message);
      } catch (error) {
        console.error("Error parsing WebSocket message:", event.data, error);
      }
    };
    
  
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  
    ws.onclose = () => {
      console.warn("WebSocket connection closed");
      setSocket(null);
    };
  
    return () => {
      ws.close();
    };
  }, [pdfKey, token]);
  
  


  // ------------------------------------------------------
  // Handle WebSocket Messages
  // ------------------------------------------------------
  const handleWebSocketMessage = (message) => {
    setChatHistory((prev) => {
      const updatedChatHistory = [...prev];
      const lastIndex = updatedChatHistory.length - 1;
  
      if (message.type === "progress") {
        if (
          updatedChatHistory[lastIndex] &&
          updatedChatHistory[lastIndex].sender === "bot" &&
          !updatedChatHistory[lastIndex].final
        ) {
          updatedChatHistory[lastIndex] = {
            ...updatedChatHistory[lastIndex],
            text: updatedChatHistory[lastIndex].text + (message.chunk || ""),
          };
        } else {
          updatedChatHistory.push({
            id: Date.now(),
            sender: "bot",
            text: message.chunk || "",
            final: false,
          });
        }
      } else if (message.type === "final") {
        if (
          updatedChatHistory[lastIndex] &&
          updatedChatHistory[lastIndex].sender === "bot"
        ) {
          updatedChatHistory[lastIndex] = {
            ...updatedChatHistory[lastIndex],
            text: updatedChatHistory[lastIndex].text + (message.data?.final_response || ""),
            final: true,
          };
        } else {
          updatedChatHistory.push({
            id: Date.now(),
            sender: "bot",
            text: message.data?.final_response || "",
            final: true,
          });
        }
  
        if (message.data?.generated_questions) {
          const questions = message.data.generated_questions.split(";").map((q) => q.trim());
          setGeneratedQuestions(questions);
          setShowDummyMessages(true);
          setClickedIndex(null);
          streamDummyMessages(questions.slice(0, 2));
        }
  
        setIsAwaitingResponse(false);
        setIsThinking(false);
      } else {
        console.warn("Unhandled message type:", message);
      }
      return updatedChatHistory;
    });
  };
  

  // ------------------------------------------------------
  // Sending a new message over WebSocket
  // ------------------------------------------------------
  const handleSend = () => {
    if (!clientMessage.trim() || isAwaitingResponse || !socket) return;

    setShowDummyMessages(false);
    const userMessage = { id: Date.now(), sender: "user", text: clientMessage };

    setChatHistory((prev) => [...prev, userMessage]);

    const messagePayload = { client_message: clientMessage };
    socket.send(JSON.stringify(messagePayload));

    setClientMessage(""); // Clear input
    setIsAwaitingResponse(true);
    setIsThinking(true);
  };





  // ------------------------------------------------------
  // Scroll to bottom when chatHistory updates
  // ------------------------------------------------------
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  // ------------------------------------------------------
  // Example: Dummy messages streaming
  // ------------------------------------------------------
  const streamText = (text, callback, delay = 10) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      const chunk = text.slice(0, charIndex + 1);
      callback(chunk);
      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(interval);
      }
    }, delay);
  };

  const streamDummyMessages = (questions) => {
    console.log("questions", questions);

    const maxToShow = 2;
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(Array(maxToShow).fill(""));

    limitedQuestions.forEach((question, index) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updated = [...prev];
          updated[index] = question.slice(0, charIndex + 1);
          return updated;
        });
        charIndex += 1;
        if (charIndex === question.length) clearInterval(interval);
      }, 20);
    });
  };


  // ------------------------------------------------------
  // If partial/finalAnswers come from redux (Optional usage)
  // ------------------------------------------------------
  useEffect(() => {
    if (partialAnswer) {
      // If you are still using Redux partial answers
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        }
        return prev;
      });
    }
  }, [partialAnswer]);

  useEffect(() => {
    if (finalAnswer && !chatHistory.some(msg => msg.text === finalAnswer)) {
      // If you are still using Redux final answers
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = finalAnswer;
          lastMessage.final = true;
          return [...prev];
        }
        return [...prev, { sender: "bot", text: finalAnswer, final: true }];
      });
      setIsAwaitingResponse(false);
      setIsThinking(false);

      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        streamDummyMessages(questions.slice(0, 2));
      }
      setShowDummyMessages(true);
    }
  }, [finalAnswer, generatedQuestions]);

  const addMessage = (newMessage) => {
    setChatHistory((prev) => {
      if (!prev.find((msg) => msg.text === newMessage.text && msg.sender === newMessage.sender)) {
        return [...prev, newMessage];
      }
      return prev;
    });
  };


  // ------------------------------------------------------
  // If we once had a "summary fetch" logic, you can restore it if needed
  // ------------------------------------------------------

  // ------------------------------------------------------
  // Thumbs Up/Down Feedback (Optional)
  // ------------------------------------------------------
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
    // If you want to send "thumbs up" feedback here, do so
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));
    // If you want a popup or to send "thumbs down" feedback
    setMessageIdToFeedback(messageId);
    setRunIdToFeedback(runId);
    setSelectedFeedbackType("down");
    setShowThumbsDownPopup(true);
  };

  // ------------------------------------------------------
  // Copying message text
  // ------------------------------------------------------
  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  // ------------------------------------------------------
  // Thumbs Down Modal
  // ------------------------------------------------------
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() &&
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");
    setFeedbackMessage(combinedFeedback);

    // Example: Submit feedback via API
    try {
      // your submitFeedback logic
      // await submitFeedback();
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  // ------------------------------------------------------
  // Cleanup on unmount
  // ------------------------------------------------------
  useEffect(() => {
    return () => {
      dispatch(resetPdfChatState());
      if (socket) {
        socket.close();
      }
    };
  }, [dispatch, socket]);


  // If you have dummy messages from generatedQuestions, handle them
  useEffect(() => {
    if (generatedQuestions && generatedQuestions.length > 0) {
      streamDummyMessages(generatedQuestions);
    }
    console.log("generatedQuestions", generatedQuestions);

  }, [generatedQuestions]);

  // ------------------------------------------------------
  // Additional Utility Functions
  // ------------------------------------------------------
  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index);
      setClientMessage(dummyMessage.trim());
    }
  };


  const handleViewDetails = (pdfKey, docType, file = null) => {
    if (!pdfKey) {
      alert("File key is not available.");
      return;
    }
    if (docType === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey: pdfKey, pdfFile: file },
      });
    } else if (docType === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: pdfKey || "defaultInvoiceKey" },
      });
    } else {
      console.warn("Unsupported document type:", docType);
    }
  };

  const handleChatWithDoc = () => {
    if (!pdfKey || !pdfUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }
    navigate("/chat", {
      state: { pdfUrl: pdfUrl, pdfKey: pdfKey },
    });
  };

  // ------------------------------------------------------
  // "New Chat" example function (unrelated to the doc chat)
  // ------------------------------------------------------
  const handleNewChat = async () => {
    setChatHistory((prev) => [...prev, { sender: "user", message: chatInput }]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        { client_message: chatInput },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const newChatId = response?.data?.chat_id;
      setChatId(newChatId);

      const source = response?.data?.source || "unknown";
      const botMessage = response?.data?.data || "No response received from the server.";

      setChatHistory((prev) => [
        ...prev,
        {
          sender: "system",
          message: `${botMessage} (Source: ${source})`,
        },
      ]);
      navigate("/ai-copilot");
    } catch (error) {
      console.error("Error while fetching API response:", error);
      setChatHistory((prev) => [
        ...prev,
        {
          sender: "system",
          message: "Something went wrong. Please try again!",
        },
      ]);
    }
  };

  // ------------------------------------------------------
  // Chat History Toggle
  // ------------------------------------------------------
  const [isOpen, setIsOpen] = useState(false);
  const toggleChatHistory = () => setIsOpen(!isOpen);


  // handling see-more icon (dropdown)
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (messageId) => {
    setOpenDropdownId((prev) => (prev === messageId ? null : messageId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // ------------------------------------------------------
  // Render
  // ------------------------------------------------------
  return (
    <div className="AI-CP-full-container">
      {/* Top Buttons for new chat & chat history */}
      <div
        className={"AI-CP-CopilotChatHistoryContainer flex gap-4"}
        style={{
          left: isMenuOpen ? "250px" : "50px",
          position: "absolute",
          top: "20px",
        }}
      >
        <button
          className="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] gap-[8px] shadow-[1px_3px_6px_rgba(0,0,0,0.1),5px_10px_11px_rgba(0,0,0,0.09),11px_23px_15px_rgba(0,0,0,0.05),20px_41px_18px_rgba(0,0,0,0.01),31px_64px_20px_rgba(0,0,0,0)] flex items-center justify-center"
          onClick={handleNewChat}
        >
          <img src="/newChat-icon.svg" alt="New Chat Icon" className="w-4 h-4" />
        </button>

        <button className="chat-historyBtn" onClick={toggleChatHistory}>
          <span>Chat History</span>
          <img src={arrowForward2} alt="Forward arrow" />
        </button>

        {isOpen && (
          <div
            className="chat-historyDiv"
            style={{
              position: "absolute",
              top: "100%",
              left: "0",
              marginTop: "8px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              padding: "10px",
              zIndex: "1000",
            }}
          >
            <p>Your chat history goes here!</p>
          </div>
        )}
      </div>

      {/* Main Chat Container */}
      <div className="AI-CP-docChatContainer">
        <div className="AI-CP-docChatContent">
          {/* Chat Messages */}
          <div className="AI-CP-message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => {
              const isBot = message.sender === "bot";
              const isFirstBotMessage =
                isBot && chatHistory.findIndex((msg) => msg.sender === "bot") === index;

              return (
                <div
                  key={message.id}
                  className={`mb-2 ${message.sender === "user"
                    ? "w-full flex justify-end"
                    : "Ai-CP-bot-message-container w-full flex items-start"
                    }`}
                >
                  {isBot && (
                    <div className="bot-icon-container flex-shrink-0">
                      <img
                        src="/bot-icon.svg"
                        alt="Bot Icon"
                        className="bot-icon h-6 w-6 rounded-full mr-2"
                      />
                    </div>
                  )}

                  <div className="flex flex-col">
                    {isBot ? (
                      <>
                        {isThinking && index === chatHistory.length - 1 ? (
                          <div className="AI-CP-thinking-indicator mb-4">
                            <span className="AI-CP-dot"></span>
                            <span className="AI-CP-dot"></span>
                            <span className="AI-CP-dot"></span>
                          </div>
                        ) : (
                          <span
                            className="AI-CP-bot-message-content"
                            dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
                          />
                        )}
                        {message.final && (
                          <div className="AI-CP-reactionContainer">
                            <div className="Ai-CP-reactions flex gap-2 text-gray-500 mt-2">
                              {/* Show "See More" only if not the first bot message */}
                              {!isFirstBotMessage && (
                                <span className="see-more-reaction-icon cursor-pointer" ref={dropdownRef}>
                                  <img
                                    src="/more-icon.svg"
                                    alt="more icon"
                                    onClick={() => toggleDropdown(message.id)}
                                  />
                                  {openDropdownId === message.id && (
                                    <div className="dropdown-menu">
                                      {docType !== "normal_document" && (
                                        <button
                                          className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px] gap-[10px]"
                                          onClick={() => handleViewDetails(pdfKey, docType)}
                                        >
                                          View More
                                        </button>
                                      )}
                                      <button onClick={handleChatWithDoc}>
                                        <img src="/chatButton-icon.svg" alt="chat-button" />
                                        <span>Chat</span>
                                      </button>
                                    </div>
                                  )}
                                </span>
                              )}

                              <span
                                className="reaction-icon cursor-pointer"
                                onClick={() => handleThumbsUpClick(message.id)}
                              >
                                <img
                                  src={
                                    thumbsUpClicked[message.id]
                                      ? "/thums-up-filled.svg"
                                      : "/thums-up.svg"
                                  }
                                  alt="Thumbs Up"
                                />
                              </span>

                              <span
                                className="reaction-icon cursor-pointer"
                                onClick={() => handleThumbsDownClick(message.id)}
                              >
                                <img
                                  src={
                                    thumbsDownClicked[message.id]
                                      ? "/thums-down-filled.svg"
                                      : "/thums-down.svg"
                                  }
                                  alt="Thumbs Down"
                                />
                              </span>

                              <span
                                className="reaction-icon cursor-pointer"
                                onClick={() => handleCopy(message.id, message.text)}
                              >
                                <img
                                  src={
                                    copiedMessageId === message.id
                                      ? "/copy-clicked.svg"
                                      : "/copy.svg"
                                  }
                                  alt="Copy"
                                />
                              </span>
                            </div>

                            {isFirstBotMessage && (
                              <div className="AI-CP-reaction-buttons">
                                {docType !== "normal_document" && (
                                  <button
                                    className="w-[120px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[20px] py-[6px] gap-[10px]"
                                    onClick={() => handleViewDetails(pdfKey, docType)}
                                  >
                                    View More
                                  </button>
                                )}

                                <button
                                  className="w-[99px] h-[34px] rounded-[6px] border border-black/10 bg-[#e9e9eb] px-[35px] py-[6px] gap-[10px]"
                                  onClick={handleChatWithDoc}
                                >
                                  Chat
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <span className="AI-CP-user-message-bubble">{message.text}</span>
                    )}
                  </div>
                </div>
              );
            })}






            {/* Error Display */}
            {error && (
              <div className="AI-CP-bot-message-container w-full flex items-start mb-4">
                <div className="AI-CP-bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>


          {/* Dummy Messages (Example Questions) */}
          {showDummyMessages && generatedQuestions.length > 0 && (
            <div className="AI-CP-dummy-container ">
              {typedDummyMessages.map((question, index) => (
                <div
                  key={index}
                  className={`AI-dummy-message-box  border border-purple-400 rounded-lg p-4 mt-4 flex items-center  cursor-pointer ${clickedIndex === index ? "opacity-50" : "opacity-100"
                    }`}
                  onClick={() => handleDummyMessageClick(question.trim(), index)}
                >
                  <span className="icon mr-2">
                    <img src="/dummy-prompt-icon.svg" alt="Star Icon" />
                  </span>
                  <p className="text-sm">{question.trim()}</p>
                </div>
              ))}
            </div>
          )}


          {/* Input & Send Button */}
          <div className="AI-CP-input-container-holder">
            <textarea
              placeholder={
                isSummaryLoading ? "Summary is loading..." : "Ask me anything"
              }
              className="AI-CP-input-field-holder"
              value={clientMessage}
              onChange={(e) => {
                if (!isAwaitingResponse && !isSummaryLoading) {
                  setClientMessage(e.target.value);
                  if (textareaRef.current) {
                    textareaRef.current.style.height = "auto";
                    textareaRef.current.style.height = `${Math.min(
                      e.target.scrollHeight,
                      88
                    )}px`;
                  }
                }
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !isAwaitingResponse &&
                  !isSummaryLoading
                ) {
                  e.preventDefault();
                  if (clientMessage.trim()) {
                    handleSend();
                    setClientMessage("");
                  }
                }
              }}
              ref={textareaRef}
              disabled={isAwaitingResponse || isSummaryLoading}
            ></textarea>

            <button
              className="AI-CP-send-button-holder"
              onClick={() => {
                setTextAreaHeight("15px");
                handleSend();
              }}
              disabled={isAwaitingResponse || isSummaryLoading}
            >
              <img
                src={
                  isSummaryLoading
                    ? "/loading-send-button.svg"
                    : isAwaitingResponse
                      ? "/loading-send-button.svg"
                      : "/send.svg"
                }
                alt="Send"
              />
            </button>
          </div>
        </div>
      </div>

      {/* Thumbs Down Popup */}
      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div className="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div className="line-with-text">
                  <span>Or</span>
                </div>
                <div className="rectangular-box">
                  <div className="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      maxLength="250"
                      placeholder="Your feedback is much appreciated and helps improve the AI!"
                      value={feedbackMessage}
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                  </div>
                  <p className="character-count">Maximum: 250 characters</p>
                </div>
                <button
                  className={`custom-button-thumsdown ${isSubmitDisabled() ? "disabled-button-thumsdown" : "enabled-button-thumsdown"
                    }`}
                  onClick={handleSubmitThumbsDownFeedback}
                  disabled={isSubmitDisabled()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopilotChat;
