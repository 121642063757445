import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPdfChat = createAsyncThunk(
  'pdfChat/fetchPdfChat',
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return rejectWithValue('No authorization token found');
    }

    const wsUrl = `wss://wa-docusensa-backend-staging-bhbmcve5e7atgwcs.centralindia-01.azurewebsites.net/ws/qdrant-chat/${pdfKey}/?token=${token}`;
    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log('WebSocket connection established:', wsUrl);
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus('loading'));
        dispatch(updatePartialAnswer(''));
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === 'final') {
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            generatedQuestions = data.data.generated_questions;

            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(rejectWithValue('WebSocket connection error'));
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
    });
  }
);




export const fetchDocumentSummary = createAsyncThunk(
  "chat/fetchDocumentSummary",
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    

    const wsUrl = `wss://wa-docusensa-backend-staging-bhbmcve5e7atgwcs.centralindia-01.azurewebsites.net/ws/pdf-summary-and-chats/${pdfKey}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log("WebSocket connection established for contract chat.");
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
        
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        try {
          const data = JSON.parse(event.data);
          console.log("Parsed WebSocket message data:", data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            console.log("Accumulated Answer:", accumulatedAnswer);
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === "final") {
            console.log("Final WebSocket message data:", data);
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            const summary = data.data.summary; // Add this
            console.log("Summary received:", data.data.summary);

            dispatch(updateFinalSummary(summary));
            generatedQuestions = data.data.example_questions || "";
            console.log("questionssssssssssss", generatedQuestions);

            dispatch(updateGeneratedQuestions(generatedQuestions)); // Update the state with the summary
            dispatch(
              updateBotMessageInfo({ botMessageId, runId, generatedQuestions })
            );
            dispatch(updateStatus("succeeded"));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed.");
      };
    });
  }
);



const pdfChatSlice = createSlice({
  name: 'pdfChat',
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, 
    runId: null,
    generatedQuestions: null,
    feedback: {},
  },
  initialState: {
    messages: [],
    partialAnswer: "",
    finalAnswer: "",
    partialSummary: "",
    finalSummary: "",
    status: "idle",
    summaryStatus: "idle",
    error: null,
    summaryError: null,
    botMessageId: null,
    runId: null,
    generatedQuestions: null,
    feedback: {},
  },
  reducers: {
    resetPdfChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null; 
      state.runId = null;
      state.generatedQuestions = null;
      state.feedback = {}; 
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId , generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions; 
    },
    updateFeedback: (state, action) => {
      const { messageId, feedbackType } = action.payload;
      state.feedback[messageId] = feedbackType;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updateGeneratedQuestions: (state, action) => {
      state.generatedQuestions = action.payload;
    },
    updatePartialSummary: (state, action) => {
      state.partialSummary = action.payload;
    },
    updateFinalSummary: (state, action) => {
      state.finalSummary = action.payload;
    },
    updateSummaryStatus: (state, action) => {
      state.summaryStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdfChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPdfChat.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchDocumentSummary.pending, (state) => {
        state.summaryStatus = "loading";
      })
      .addCase(fetchDocumentSummary.fulfilled, (state) => {
        state.summaryStatus = "succeeded";
      })
      .addCase(fetchDocumentSummary.rejected, (state, action) => {
        state.summaryError = action.payload;
        state.summaryStatus = "failed";
      })
      .addCase(fetchPdfChat.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});
export const { resetPdfChatState, updatePartialAnswer, updatePartialSummary,
  updateFinalSummary,
  updateSummaryStatus,
  updateGeneratedQuestions, updateFinalAnswer, updateBotMessageInfo, updateStatus } = pdfChatSlice.actions;
export default pdfChatSlice.reducer;
