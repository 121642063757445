import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import {
  fetchChatStream,
  resetChatState,
  updatePartialAnswer,
} from "../../Features/chatSlice";
import "./contractChat.css";
import "./FolderChat.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
const FolderChat = () => {
  const chatContainerRef = useRef(null);
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const folderId = location.state?.folderId;
  const pdfList = location.state?.pdfList || [];
  const [selectedPdf, setSelectedPdf] = useState(pdfList[0]?.pdfUrl || "");
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clientMessage, setClientMessage] = useState("");
  const [isSummaryComplete, setIsSummaryComplete] = useState(false);
  const dispatch = useDispatch();
  const partialAnswer = useSelector((state) => state.chat.partialAnswer);
  const finalAnswer = useSelector((state) => state.chat.finalAnswer);
  const status = useSelector((state) => state.chat.status);
  const error = useSelector((state) => state.chat.error);
  const { botMessageId, runId, generatedQuestions } = useSelector(
    (state) => state.chat
  );
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const [showEarlierMessagesPopup, setShowEarlierMessagesPopup] =
    useState(false);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  console.log("Generated Questions:", generatedQuestions);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const textareaRef = useRef(null);
  const summaryStatus = useSelector(
    (state) => state.chat?.summaryStatus || "idle"
  );
  const isSummaryLoading = summaryStatus === "loading";
  const [isSummaryCompleted, setIsSummaryCompleted] = useState(false);
  const { pdfUrl, pdfKey } = location.state || {};
  const [chatHistory, setChatHistory] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);
  const [isFetchingSummary, setIsFetchingSummary] = useState(false);
  const [isFolderEmptyPopupVisible, setIsFolderEmptyPopupVisible] =
    useState(false);

  const formatText = useCallback((input) => {
    if (typeof input !== "string") {
      console.error("Invalid input to formatText:", input);
      return "";
    }
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/__(.*?)__/g, "<u>$1</u>")
      .replace(/~~(.*?)~~/g, "<del>$1</del>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");

    return `<p>${formattedText}</p>`;
  }, []);

  const partialSummary = useSelector(
    (state) => state.chat?.partialSummary || ""
  );
  const finalSummary = useSelector(
    (state) => state.chat?.finalSummary || ""
  );
 
  useEffect(() => {
    if (partialSummary) {
      setChatHistory((prev) => {
        const lastMessage = prev[0];
        if (
          lastMessage?.sender === "bot" &&
          lastMessage.text.startsWith("Summary")
        ) {
          lastMessage.text = partialSummary;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialSummary
          )
        ) {
          return [
            { id: Date.now(), sender: "bot", text: partialSummary },
           
          ];
        }
        return prev;
      });
    }
  }, [partialSummary]);

  useEffect(() => {
    if (finalSummary) {
      setChatHistory((prev) => {
        const lastMessage = prev[0];
        if (lastMessage?.sender === "bot") {
          lastMessage.text = finalSummary;
          return [...prev];
        }
        return [{ id: Date.now(), sender: "bot", text: finalSummary }, ...prev];
      });
      setIsSummaryCompleted(true);
    }
  }, [finalSummary]);



  useEffect(() => {
    const handleScroll = () => {
      const shouldShow =
        chatContainerRef.current.scrollTop <
        chatContainerRef.current.scrollHeight -
          chatContainerRef.current.clientHeight -
          100;
      setShowScrollButton(shouldShow);
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const submitFeedback = async (
    messageId,
    runId,
    feedbackType,
    userFeedback
  ) => {
    const payload = {
      message_id: botMessageId,
      run_id: runId,
      feedback_type: feedbackType,
      user_feedback: String(userFeedback).trim(),
    };

    console.log("Final Payload Sent to API:", payload);
    try {
      if (!messageId || !runId || !feedbackType) {
        console.error("Missing required data for feedback submission.");
        return;
      }

      const token = localStorage.getItem("token");
      console.log("shdshd", token);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data);
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  const navigate = useNavigate();
  const handleCloseEmptyFolderPopup = () => {
    setIsFolderEmptyPopupVisible(false);
    navigate("/uploadFile");
  };

  const handleThumbsUpClick = (messageId) => {
    const isCurrentlyClicked = thumbsUpClicked[messageId];

    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !isCurrentlyClicked,
    }));

    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));

    if (!isCurrentlyClicked) {
      setPopupVisible(true);
      setMessageIdToFeedback(botMessageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback(botMessageId, runId, "up");
    }
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false,
    }));

    if (!thumbsDownClicked[messageId]) {
      setMessageIdToFeedback(botMessageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };
  const streamText = (text, callback, delay = 5) => {
    let charIndex = 0;

    const stream = () => {
      if (charIndex < text.length) {
        callback(text.slice(0, charIndex + 1));
        charIndex++;
        setTimeout(stream, delay);
      }
    };

    stream();
  };

  const [hasSummaryBeenAdded, setHasSummaryBeenAdded] = useState(false);
  const fetchController = useRef(null);

  useEffect(() => {
    const fetchFolderSummaryAndQuestions = async () => {
      if (hasSummaryBeenAdded || !folderId) return;

      try {
        setIsSummaryCompleted(false);
        setChatHistory((prev) => {
          if (
            !prev.some(
              (msg) => msg.sender === "bot" && msg.text === "Summarizing..."
            )
          ) {
            return [
              { id: Date.now(), sender: "bot", text: "Summarizing..." },
              ...prev,
            ];
          }
          return prev;
        });
        setIsThinking(true);
        setIsSummaryCompleted(false);
        if (fetchController.current) {
          fetchController.current.abort();
        }
        fetchController.current = new AbortController();

        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/readpdf/folder-summary-and-questions/${folderId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
            signal: fetchController.current.signal,
          }
        );

        if (
          response.data.msg === "Folder is empty. Please upload files to chat."
        ) {
          setIsFolderEmptyPopupVisible(true);
          setIsSummaryCompleted(true);
          setIsSummaryCompleted(false);
          return;
        }

        const { meta_summary, sample_questions, chats } = response.data;
        if (meta_summary) {
          setChatHistory((prev) => {
            const updatedHistory = prev
            .filter(
              (msg) =>
                msg.sender !== "bot" ||
                (msg.text !== "Summarizing..." &&
                  msg.text !==
                    "Failed to fetch summary. Please try again later.")
            )
            .map((msg) =>
              msg.sender === "bot" && msg.text === "Summarizing..."
                ? { ...msg, text: meta_summary }
                : msg
            );

          // Ensure no duplicate summaries
          if (
            updatedHistory.some(
              (msg) => msg.sender === "bot" && msg.text === meta_summary
            )
          ) {
            return updatedHistory;
          }

          return [
            { id: Date.now(), sender: "bot", text: meta_summary},
            ...updatedHistory,
          ];
        });
      }

      if (chats) {
        setChatHistory((prev) => {
          const existingMessageIds = new Set(prev.map((msg) => msg.id));
          const newMessages = chats
            .filter((item) => !existingMessageIds.has(item.message_id))
            .map((item) => ({
              id: item.message_id,
              sender: item.is_client ? "user" : "bot",
              text: item.message,
              timestamp: item.timestamp,
            }));

          return [...prev, ...newMessages];
        });
      }

      setIsSummaryCompleted(true);

      if (sample_questions) {
        const questions = sample_questions.split(";").map((q) => q.trim());
        streamDummyMessages(questions);
        console.log("Parsed Questions:", questions); 
      }
     
    } catch (error) {
      console.error("Error fetching summary and questions:", error);

      setChatHistory((prev) => {
        return prev.map((msg) =>
          msg.sender === "bot" && msg.text === "Summarizing..."
            ? {
                ...msg,
                text: "Failed to fetch summary. Please try again later.",
              }
            : msg
        );
      });
    } finally {
      setIsAwaitingResponse(false);
    }
  
    
      
     
    };

    fetchFolderSummaryAndQuestions();

    return () => {
      if (fetchController.current) {
        fetchController.current.abort();
      }
    };
  }, [folderId]);

  const streamDummyMessages = (questions) => {
    const maxToShow = 2; // Adjust as needed
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(maxToShow).fill(""));
  
    limitedQuestions.forEach((question, index) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[index] = question.slice(0, charIndex + 1);
          return updatedMessages;
        });
        charIndex += 1;
        if (charIndex >= question.length) clearInterval(interval);
      }, 10); // Adjust delay as needed
    });
  };
  

  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialAnswer
          )
        ) {
          return [
            ...prev,
            { sender: "bot", text: partialAnswer, final: false },
          ];
        }
        return prev;
      });
    }
  }, [partialAnswer]);

  useEffect(() => {
    if (finalAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = finalAnswer;
          lastMessage.final = true;
          return [...prev];
        } else if (
          !prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)
        ) {
          return [...prev, { sender: "bot", text: finalAnswer, final: true }];
        }
        return prev;
      });
      setIsAwaitingResponse(false);
      setIsThinking(false);

      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        streamDummyMessages(questions.slice(0, 2));
      }

      setShowDummyMessages(true);
    }
  }, [finalAnswer, generatedQuestions]);

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => {
      const updatedState = {
        ...prev,
        [id]: checked,
      };
      console.log("Updated Checkbox State:", updatedState); // Debug log
      return updatedState;
    });
  };
  const handleTextareaChange = (e) => {
    setFeedbackMessage(e.target.value);
  };

  const handleSubmitThumbsDownFeedback = async () => {
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }

    const combinedFeedback = [...selectedFeedback, feedbackMessage]
      .join("\n")
      .trim();
    console.log("Combined Feedback:", combinedFeedback);

    try {
      await submitFeedback(
        messageIdToFeedback,
        runIdToFeedback,
        "down",
        combinedFeedback
      );
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting thumbs-down feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");
  };

  useEffect(() => {
    return () => {
      dispatch(resetChatState());
    };
  }, [dispatch]);

  const isSubmitDisabled = () => {
    return (
      !feedbackMessage.trim() &&
      !Object.values(selectedCheckboxes).some((checked) => checked)
    );
  };

  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);

 

  const handleSend = () => {
    if (!isSummaryCompleted || isAwaitingResponse) return;
    if (inputMessage.trim()) {
      setChatHistory((prev) => [
          ...prev,
          { 
              id: Date.now(), 
              sender: "user", 
              text: inputMessage.trim(), 
              timestamp: new Date().toISOString(),
          },
      ]);

      setChatHistory((prev) => [
        ...prev,
        {
          id: Date.now(),
          sender: "bot",
          text: "",
          final: false,
          timestamp: new Date().toISOString(),
        },
      ]);
      setIsAwaitingResponse(true);
      setIsThinking(true);
      setInputMessage("");
      setClientMessage("");
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
      dispatch(fetchChatStream({ query: inputMessage, folderId }));

      setShowDummyMessages(false);
      setClickedIndex(null);
    } else {
      console.error("Message cannot be empty");
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textAreaHeight;
    }
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight;
      }
    };
  }, [textAreaHeight]);

  useEffect(() => {
    if (status === "loading" && !partialAnswer) {
      setIsThinking(true);
    } else if (status === "succeeded" && partialAnswer) {
      setIsThinking(false);
    } else if (status === "failed") {
      setIsThinking(false);
    }
  }, [status, partialAnswer]);

  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index);
      setInputMessage(dummyMessage.trim());
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current && !isUserScrolling) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory,typedDummyMessages]);


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatHistory]);





  const groupMessagesByDate = (messages) => {
    
    const sortedMessages = [...messages].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    const groupedMessages = {};

    sortedMessages.forEach((message) => {
      let label;

      try {
        const messageDate = message.timestamp
          ? new Date(message.timestamp)
          : null;

        if (!messageDate || isNaN(messageDate.getTime())) {
          label = "Summary";
        } else {
          const today = new Date();
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);

          if (messageDate.toDateString() === today.toDateString()) {
            label = "Today";
          } else if (messageDate.toDateString() === yesterday.toDateString()) {
            label = "Yesterday";
          } else {
            label = format(messageDate, "dd MMM yyyy");
          }
        }
      } catch (error) {
        console.error("Error processing message timestamp:", message, error);
        label = "Invalid Date";
      }

      if (!groupedMessages[label]) {
        groupedMessages[label] = [];
      }
      groupedMessages[label].push(message);
    });

    return groupedMessages;
  };

  const groupedChatHistory = groupMessagesByDate(chatHistory);

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollHeight - clientHeight - scrollTop < 20;
  
      if (!isAtBottom) {
        setIsUserScrolling(true);
      } else {
        setIsUserScrolling(false);
      }
  
      if (scrollTop > 50) {
        setShowEarlierMessagesPopup(true);
  
        if (scrollHeight - clientHeight - scrollTop < 20) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      } else {
        setShowEarlierMessagesPopup(false);
        setIsScrolling(false);
      }
    
    }
  };
  
  

  let scrollTimeout;
const handleScrollWithTimeout = () => {
  handleScroll();
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => setIsUserScrolling(false), 1000);
};

useEffect(() => {
  const container = chatContainerRef.current;
  if (container) {
    container.addEventListener("scroll", handleScrollWithTimeout);
  }
  return () => {
    if (container) {
      container.removeEventListener("scroll", handleScrollWithTimeout);
    }
    clearTimeout(scrollTimeout);
  };
}, []);
useEffect(() => {
  const container = chatContainerRef.current;
  if (container) {
    container.addEventListener("scroll", handleScroll);
  }
  return () => {
    if (container) {
      container.removeEventListener("scroll", handleScroll);
    }
  };
}, []);

useEffect(() => {
  if (clientMessage.trim() !== "") {
    setShowEarlierMessagesPopup(false);
  }
}, [clientMessage]);

  return (
    <>
      <div className="full-container">
        <div className="custom-header-wrapper -ml-16">
          <Header icon="/arrow-back.svg" name="Go to Documents" />
        </div>
        <div className="content-container">
        <div className="left-side">
          {showEarlierMessagesPopup && (
            <div
              className="fixed transform -translate-x-1/2 inline-flex items-center justify-center gap-1.5 px-4 py-1 text-sm font-outfit font-normal text-primary-500 border border-black/10 rounded-full bg-gradient-to-r from-[#F5F4F3] to-[#FCDFF9] shadow-custom "
              style={{
                top: `${55}px`,
                left: `${400}px`,
                color: "#C95EBE", 
                fontFamily: "Outfit",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
                opacity: isScrolling ? "0.5" : "3",
              }}
            >
              <span>See earlier messages</span>
            </div>
          )}

          <div className="message-container " ref={chatContainerRef}>
            {Object.entries(groupedChatHistory)
              .sort(([dateA], [dateB]) => {
                if (dateA === "Today") return 1;
                if (dateB === "Today") return -1;
                return new Date(dateA) - new Date(dateB);
              })
              .map(([dateLabel, messages]) => (
                <div key={dateLabel}>
                  {/* Date Label */}
                  <div className="flex items-center justify-center my-4">
                    <div className="h-px w-[269px] bg-black/10"></div>
                    <div className="flex items-center justify-center gap-1 px-4 py-1.5 mx-3 rounded-full border border-black/10 shadow-md text-neutral-600 text-sm font-normal">
                      {dateLabel}
                    </div>
                    <div className="h-px w-[269px] bg-black/10"></div>
                  </div>

                  {/* Messages for the Date */}
                  {messages.map((message, index) => (
                    
                    <div
                      key={message.id || index}
                      className={`mb-0 ${
                        message.sender === "user"
                          ? "w-full flex justify-end"
                          : "bot-message-container w-full flex items-start"
                      }`}
                    >
                      {/* Bot Messages */}
                      {message.sender === "bot" && (
                        <div className="bot-icon-container flex-shrink-0">
                          <img
                            src="/bot-icon.svg"
                            alt="Bot Icon"
                            className="bot-icon h-6 w-6 rounded-full mr-2"
                          />
                        </div>
                      )}
                      {message.sender === "bot" ? (
                        <div className="flex flex-col">
                          {(isThinking && index === chatHistory.length - 1) ||
                          message.text === "" ? (
                            <div className="thinking-indicator mb-4">
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          ) : (
                            <span
                              className="bot-message-content"
                              
                              dangerouslySetInnerHTML={{
                                __html: formatText(message.text),
                              }}
                            />
                          )}

                          {/* Reactions */}
                          <div className="reactions flex gap-2 text-gray-500 mt-2">
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => handleThumbsUpClick(message.id)}
                            >
                              <img
                                src={
                                  thumbsUpClicked[message.id]
                                    ? "/thums-up-filled.svg"
                                    : "/thums-up.svg"
                                }
                                alt="Thumbs Up"
                              />
                              <span className="reaction-tooltip">
                                Thumbs Up
                              </span>
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() => handleThumbsDownClick(message.id)}
                            >
                              <img
                                src={
                                  thumbsDownClicked[message.id]
                                    ? "/thums-down-filled.svg"
                                    : "/thums-down.svg"
                                }
                                alt="Thumbs Down"
                              />
                              <span className="reaction-tooltip">
                                Thumbs Down
                              </span>
                            </span>
                            <span
                              className="reaction-icon cursor-pointer"
                              onClick={() =>
                                handleCopy(message.id, message.text)
                              }
                            >
                              <img
                                src={
                                  copiedMessageId === message.id
                                    ? "/copy-clicked.svg"
                                    : "/copy.svg"
                                }
                                alt="Copy"
                              />
                              <span className="reaction-tooltip">Copy</span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        
                        <div className="w-full flex justify-end items-start gap-4">
                        {/* User Message */}
                        <div className="user-message-bubble">
                          {message.text}
                        </div>
                        {/* User Icon */}
                        <div className="user-icon-container flex-shrink-0 self-end">
                          <div className="user-icon w-7 h-7 flex items-center justify-center rounded-full bg-white text-[#C95EBE] border border-[rgba(0,0,0,0.10)] text-[13px] font-outfit font-medium leading-none mr-3">
                            AM
                          </div>
                        </div>
                      </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}

            {error && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}

            <div className="dummy-container flex flex-col gap-2 mr-20 mb-4">
              {showDummyMessages &&
                typedDummyMessages.map((question, index) => (
                  <div
                    key={index}
                    className={`dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center cursor-pointer ${
                      clickedIndex === index ? "opacity-50" : "opacity-100"
                    }`}
                    onClick={() =>
                      handleDummyMessageClick(question.trim(), index)
                    }
                  >
                    <span className="icon mr-2">
                      <img src="/star-dot.svg" alt="Star Icon" />
                    </span>
                    <p className="text-sm">{question.trim()}</p>
                  </div>
                ))}
            </div>
          </div>

          <div className="custom-input-wrapper"
           style={{
            marginLeft: "-35px",
            marginTop: "-35px",
            backgroundColor: "#FEF9F7",
          }}>
              <div className="input-container-holder">
                <textarea
                  placeholder={
                    isSummaryLoading
                      ? "Summarizing..."
                      : isAwaitingResponse
                      ? "Copilot is responding..."
                      : "Ask me anything"
                  }
                  className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
                  style={{
                    paddingTop: "3px", 
                    marginTop: "5px",
                    lineHeight: "20px", 
                  }}
                  value={inputMessage}
                  onChange={(e) => {
                    setInputMessage(e.target.value);
                    if (textareaRef.current) {
                      textareaRef.current.style.height = "auto";
                      textareaRef.current.style.height = `${Math.min(
                        e.target.scrollHeight,
                        88
                      )}px`;
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      isSummaryCompleted &&
                      !isAwaitingResponse
                    ) {
                      e.preventDefault();
                      if (inputMessage.trim()) {
                        handleSend();
                        setInputMessage("");
                      }
                    }
                  }}
                  ref={textareaRef}
                />

                <button
                  className="send-button-holder"
                  onClick={() => handleSend()}
                  disabled={
                    isSummaryLoading ||
                    !isSummaryCompleted ||
                    isAwaitingResponse ||
                    !inputMessage.trim()
                  }
                >
                  {isSummaryLoading || isAwaitingResponse ? (
                    <img src="/loading-send-button.svg" alt="Loading" />
                  ) : (
                    <img src="/send.svg" alt="Send" />
                  )}
                </button>
              </div>
            </div>
        </div>
          <div className="right-side-folder ml-10">
            <div className="iframe-folder">
              <iframe
                src={selectedPdf}
                title="PDF Viewer"
                className="w-full h-3/4"
                allow="fullscreen"
              />
            </div>

            <div className="folder-list-container">
              <div className="folder-list-scrollable">
                {pdfList.map((pdf) => (
                  <div
                    key={pdf.pdfKey}
                    className="folder-box"
                    onClick={() => {
                      setSelectedPdf(pdf.pdfUrl);
                      setActiveFolder(pdf.pdfKey);
                    }}
                  >
                    <div
                      className={`folder-icon-container ${
                        activeFolder === pdf.pdfKey ? "active" : ""
                      }`}
                    >
                      <img
                        src="/pdf.svg"
                        alt={pdf.name}
                        className="folder-icon"
                      />
                    </div>
                    <div className="folder-container">
                      <p className="folder-name">{pdf.name}</p>
                      <span className="folderTooltip">{pdf.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {showThumbsDownPopup && (
          <div className="popup-container-thumsdown">
            <div className="popup-content-thumsdown">
              <div>
                <div>
                  <button
                    className="close-button-thumsdown"
                    onClick={() => setShowThumbsDownPopup(false)}
                  >
                    <p>x</p>
                  </button>
                </div>
                <div className="paragraph-container">
                  <p className="paragraph-container-para1">What went Wrong?</p>
                  <p className="paragraph-container-para2">
                    Select feedback that reflects how well this response
                    addressed your question or concern.
                  </p>
                  <div class="checkbox-container-thumsup">
                    <label className="thumsup-checkbox">
                      <input
                        type="checkbox"
                        className="thumbsup1"
                        id="factuallyIncorrect"
                        checked={selectedCheckboxes.factuallyIncorrect}
                        onChange={handleCheckboxChange}
                      />
                      Not factually correct.
                    </label>

                    <label className="thumsup-checkbox">
                      <input
                        type="checkbox"
                        className="thumbsup2"
                        id="refused"
                        checked={selectedCheckboxes.refused}
                        onChange={handleCheckboxChange}
                      />
                      Refused when it shouldn’t have.
                    </label>

                    <label className="thumsup-checkbox">
                      <input
                        type="checkbox"
                        className="thumbsup3"
                        id="notFollowingInstructions"
                        checked={selectedCheckboxes.notFollowingInstructions}
                        onChange={handleCheckboxChange}
                      />
                      Not Following Instructions.
                    </label>
                  </div>
                  <div class="line-with-text">
                    <span>Or</span>
                  </div>
                  <div class="rectangular-box">
                    <div class="feedback-container">
                      <textarea
                        className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                        maxLength="250"
                        placeholder="Your feedback is much appreciated and helps improve the AI!"
                        value={feedbackMessage}
                        onChange={(e) => setFeedbackMessage(e.target.value)}
                      />
                    </div>
                    <p class="character-count">Maximum: 250 characters</p>
                  </div>
                  <button
                    className={`custom-button-thumsdown ${
                      isSubmitDisabled()
                        ? "disabled-button-thumsdown"
                        : "enabled-button-thumsdown"
                    }`}
                    onClick={handleSubmitThumbsDownFeedback}
                    disabled={isSubmitDisabled()}
                  >
                    Submit
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FolderChat;
