import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './uploadDocument.css';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { FileSuccessfullyUploaded } from './FileSuccessfullyUploaded'
import { useLocation } from 'react-router-dom';


const UploadModal = ({ isOpen, onClose, onUploadSuccess, type, folderId }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [status, setStatus] = useState("");

  const [uploadFile, setUploadFiles] = useState(false);
  const pdf_key = localStorage.getItem("pdfKey");
  const [docType, setDocType] = useState('');
  const location = useLocation();
  const { invoiceKey, extractedData } = location.state || {};

  let progressInterval = null;


  const navigate = useNavigate();
  const [contractKey, setContractKey] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);

  if (!isOpen) return null;

  const triggerFileInput = () => document.getElementById('fileInput').click();



  const startProgress = () => {

    progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 90) {
          return prevProgress + 1;
        } else {
          clearInterval(progressInterval);
          return prevProgress;
        }
      });
    }, 100);
  };



  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsUploading(true);
    setIsUploaded(false);
    setProgress(0);
    startProgress();
    handleFileUpload(selectedFile);
  };


  const handleFileUpload = async (selectedFile) => {
    const token = localStorage.getItem('token');
    console.log("here", selectedFile, folderId)

    if (!token) {
      alert("Session expired, please log in.");
      onClose();
      return;
    }

    if (!selectedFile || (type === 'underFolder' && !folderId)) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    let url = type === 'document'
      ? `${process.env.REACT_APP_API_URL}/api/v1/copilot/qdrant-upload-doc/`
      : `${process.env.REACT_APP_API_URL}/api/v1/readpdf/upload-in-folder/${folderId}/`;

    // console.log("vg", url)

    try {
      const response = await axios.post(
        url,
        formData,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.min(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              90
            );
            setProgress(percentCompleted);
          },
        }
      );
      if (response.status === 200) {
        // console.log("response: ", response.data.pdf_key)
        // console.log("response data: ", response.data)
        console.log("ppppdddfffUUURRLLL" , response.data.file_url);
        localStorage.setItem('contractUrl', response.data.file_url);

        const { pdf_key, doc_type , file_url } = response.data;
        clearInterval(progressInterval);
        setTimeout(() => {
          setProgress(100);
          setTimeout(() => {
            setIsUploaded(true);
            setIsUploading(false);
            setDocType(doc_type);
            setContractKey(pdf_key);
            setContractUrl(response.data.file_url);

          }, 1000);
        }, 500);
 

      } else {
        alert(response.data.msg || "File upload failed");
        setIsUploading(false);
      }
    } catch (error) {
      console.log("Upload error:", error);
      alert(error.response?.data.msg || "An error occurred during file upload. Please try again.");
      setIsUploading(false);
    }
  };

  
  


  const handleClose = () => {
    onClose();
    if (type === 'document') {
      navigate('/ai-copilot');
    } else if (type === 'underFolder') {
      navigate(`/folder/?folderId=${folderId}`);
    } else {
      navigate('/uploadFile');
    }
  };

  const hrStyle = {
    border: '1px dashed rgba(139, 85, 221, 1)',
    marginTop: '-20px',
    width: '100%',
  };


  const handleSummarizeDocument = () => {
    if (!contractKey || !contractUrl) {
      toast.error("Document information is missing. Please try again.");
      return;
    }
    console.log("contract-key", contractKey);
    console.log("doc typeeeeeeee", docType);
    console.log("contract url", contractUrl);

    const storedContractUrl = localStorage.getItem('contractUrl');
console.log("recieved contract url",storedContractUrl);

    navigate("/CopilotChatScreen", {
      state: { pdfUrl: storedContractUrl, pdfKey: contractKey, docType: docType },
      
    });
  };

  const handleViewDetails = () => {
    if (docType === "contract") {
      navigate("/Contract/contract-details", {
        state: { contractKey, pdfFile: file }
      });
    } else if (docType === "invoice") {
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: contractKey || "defaultInvoiceKey" }
      });
    } else {
      console.warn("Unsupported document type:", docType);
    }
  };
  // console.log("document type", docType);



  return (
    <div className="AI-CP-modal-overlay-details">
      <div className="modal-content-details">
        <button className="close-button" onClick={handleClose} style={{ marginRight: '30px', fontSize: '22px' }}>
          x
        </button>
        {isUploaded ? (
          <div className='uploadedMainContainer'>
            <div className="AIsuccess-status">
              <div className="success-icon-upload">
                <img src="/right-success.svg" alt="Success Icon" className="checkmark-icon" />
              </div>
              {type === 'document' ?
                <h3 className="success-heading">Great! Your document is ready!</h3> :
                type === 'underFolder' ?
                  <h3 className="success-heading">File Uploaded to Folder Successfully</h3> :
                  <h3 className="success-heading">File Uploaded Successfully</h3>}


              {type === 'document' ?
                <p className="AI-CP-success-message-upload">Your document has been uploaded successfully! Choose your next action below.</p> :
                type === 'underFolder' ?
                  <p className="success-message">Your file has been uploaded to the folder successfully</p> :
                  <p className="success-message">Your File has been uploaded successfully</p>}

              {/* <div className="file-info">
                <span className="file-name-icon"><img src="file-icon.svg" alt="" /></span>
                <span className="file-name">{file ? file.name : "Uploaded_File.pdf"}</span>
              </div> */}
            </div>

            <hr style={hrStyle} />
            <div className="uploadedBottomContent">
              {type === 'document' && (

                <div className="AI-CP-extraction-container">
                  <img src="/bot-icon.svg" alt="bot Icon" className="extractionBot-icon" />
                  {/* <div className="extraction-message left-arrow">
                    <p>We've detected this as a Contract document. What do you want to do next?  <br /></p>
                    <p>Would you like to extract key clauses, or should i summarize it for you?</p>
                
                  </div> */}

                  {/* <div className="extraction-message left-arrow">
                    <p>We've detected this as a Normal document. What do you want to do next?  <br /></p>
                    <p>Would you like to summarize it for you?</p>
                  
                  </div> */}
                  <div className="AI-CP-extraction-message AI-CP-left-arrow">
                    {docType === "contract" || docType === "invoice" ? (
                      <>
                        <p>
                          We've detected this as a {docType === "contract" ? "Contract" : "Invoice"} document. What do you want to do next? <br />
                        </p>
                        <p>Would you like to extract key clauses, or should I summarize it for you?</p>
                      </>
                    ) : docType === "normal_document" ? (
                      <>
                        <p>
                          We've detected this as a Normal document. What do you want to do next? <br />
                        </p>
                        <p>Would you like me to summarize it for you?</p>
                      </>
                    ) : (
                      <p>Document type not recognized. Please choose an action.</p>
                    )}
                  </div>




                </div>

              )}

              {type === 'document' && (
                <div className="AI-CP-button-container">
                  {/* Always show the Summarize Document button */}
                  <button
                    className="AI-CP-summarizeDoc-button"
                    onClick={handleSummarizeDocument}
                  >
                    <img src="/copilot-icon.svg" alt="bot Icon" />
                    Summarize Document
                  </button>

                  {/* Show the Extract Key Data button for both contract and invoice documents */}
                  {(docType === "contract" || docType === "invoice") && (
                    <button className="extract-details-button" onClick={handleViewDetails}>
                      <img src="/extract-data.svg" alt="extract-detail Icon" />
                      Extract Key Data
                    </button>
                  )}
                </div>
              )}


            </div>




          </div>
        ) :
          isUploading ? (
            <div className="AI-CP-Full-Box">
              {type === "document" ? (
                <h3 className="AI-CP-Heading1 ml-20">Upload Your Document</h3>
              ) : type === "underFolder" ? (
                <h3 className="AI-CP-Heading1">Upload Your File to Folder</h3>
              ) : (
                <h3 className="AI-CP-Heading1">Upload Your Files</h3>
              )}

              {type === "document" ? (
                <p className="AI-CP-Paragraph1 ml-2 mt-1">Upload your document here and start extracting data</p>
              ) : type === "underFolder" ? (
                <p className="AI-CP-Paragraph1">Upload your file here and store it in a folder</p>
              ) : (
                <p className="AI-CP-Paragraph1">Upload your file here and start extracting data</p>
              )}

              <div className="AI-CP-uploading-box">
                <div class="AI-CP-uploadingIconContainer mb-2">
                  <img src="/uploadingDoc.svg" alt="" className="AI-CP-uploadingIcon " />
                  <img src="/uploadingDoc2.svg" alt="" className="AI-CP-uploadingIcon2" />
                </div>


                <p className="AI-CP-status-text-upload mb-8">Uploading your document... Sit tight while we process your file!</p>

                {/* <p className="progress-label">Your Progress</p> */}
                <div className="progessText">
                  <p className="AI-CP-progress-percentage-upload">{progress}% to complete</p>
                  <div className="AI-CP-time-estimate">
                    <span className="time-icon">⏱</span> 1min
                  </div>
                </div>

                <div className="AI-CP-progress-bar-cont">
                  <div className="AI-CP-progress-bar-fill" style={{ width: `${progress}%` }}></div>
                </div>
              </div>

              <div className="AI-CP-file-info">
                <div className="AI-CP-AI-CP-file-info-container">
                  <div className="AI-CP-support" style={{ position: "relative", left: "12rem" }}>
                    Supported formats: PDF
                  </div>
                </div>
                <div className="AI-CP-file-info-container">
                  <div
                    className="AI-CP-maximum"
                    style={{
                      position: "relative",
                      left: "-57px",
                    }}
                  >
                    Maximum Size: 20MB
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="AI-CP-Full-Box">

              {type === 'document' ?
                <h3 className="AI-CP-Heading1">Let's Get Started with Your Document!</h3> :
                type === 'underFolder' ?
                  <h3 className="AI-CP-Heading1">Upload Your File to Folder</h3> :
                  <h3 className="AI-CP-Heading1">Upload Your Files</h3>}

              {type === 'document' ?
                <p className="AI-CP-Paragraph1">Upload your document here and start extracting data</p> :
                type === 'underFolder' ?
                  <p className="AI-CP-Paragraph1">Upload your file here and store it in a folder</p> :
                  <p className="AI-CP-Paragraph1">Upload your file here and start extracting data</p>}

              <div className="AI-CP-upload-box" onClick={triggerFileInput}>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".pdf"
                />
                <div className="AI-CP-upload-icon">
                  <img src="/uploadBig.svg" alt="Upload Icon" className="AI-CP-upload-big-icon" />
                  <img src="/uploadSmall.svg" alt="Overlay Icon" className="AI-CP-upload-small" />
                </div>
                <p className="AI-CP-main-text">Drag and Drop Files Here or <span className="choose-file">Choose File</span></p>
              </div>

              <div className="AI-CP-file-info">
                <div className="AI-CP-file-info-container">
                  <div className="support" style={{ position: 'relative', left: '12rem' }}>
                    Supported formats: PDF
                  </div>
                </div>
                <div className="AI-CP-file-info-container">
                  <div
                    className="maximum"
                    style={{
                      position: 'relative',
                      left: '-30px',
                    }}
                  >Maximum Size: 20MB</div>
                </div>
              </div>


            </div>
          )}
      </div>
    </div>
  );
};

export default UploadModal;