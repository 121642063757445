import React, { createContext, useState, useContext } from "react";

// Step 1: Create a Context
const InputFieldContext = createContext();

// Step 2: Create a Provider Component
export const InputFieldProvider = ({ children }) => {
  const [appendButton, setAppendButton] = useState(false); // Shared state

  return (
    <InputFieldContext.Provider value={{ appendButton, setAppendButton }}>
      {children}
    </InputFieldContext.Provider>
  );
};

// Step 3: Create a hook to use the context
export const useInputField = () => useContext(InputFieldContext);



