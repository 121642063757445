import React, { useState, useEffect, useMemo, useRef } from 'react'
import Menu from '../chat/Menu'
import "./aicopilot.css"
import backup from "../../Images/backup.png"
import plus from "../../Images/plus1.png"
import send from "../../Images/send.png"
import bell from "../../Images/ai-bell.png"
import search from "../../Images/search-big.jpg"
import { Header } from '../../components/Header/Header'
import axios from "axios";
import { v4 as uuidv4 } from 'uuid'; // Importing the UUID function
import { toast } from "react-toastify";
import uploadlogo from "../../Images/after-upload.png"
import blueai from "../../Images/blue-ai.png"
import findImage from "../../Images/find_in_page.png"
import find from "../../Images/find.jpg"
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FileSuccessfullyUploaded } from './FileSuccessfullyUploaded'
import AICopilotIcon from '../../Images/ai-copiot-icon.svg';
import UploadIcon from '../../Images/upload-icon.svg';
import summarizeIcon from '../../Images/summarizeText-icon.svg';
import arrowForward from '../../Images/arrow_forward.svg';
import InvoiceIcon from '../../Images/invoice-icon.svg';
import SpreadsheetIcon from '../../Images/spreadsheet-icon.svg';
import DocumentUploadModal from '../AI_Copilot/uploadDocument.jsx';
import arrowForward2 from '../../Images/arrow_forward-2.svg';
import UploadModal from "../Contract/UploadModal.jsx";
import ExplorePrompt from './explorePromptModal.jsx';
import ContractSearch from '../../components/searchContracts.jsx';
import { useInputField } from './InputFieldContext.js';
import PromptChat from './PromptChat.jsx'
import ChatHistory from './ChatHistory.jsx'



export const AIcopilot = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [chatInput, setChatInput] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null); // Track selected document
  const [clickedCardIndex, setClickedCardIndex] = useState(null); // Tracks which card is clicked
  const [showContractSearch, setShowContractSearch] = useState(false); // Tracks if ContractSearch should be shown
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [clickedCards, setClickedCards] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [uploadFile, setUploadFiles] = useState(false);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [contractKey, setContractKey] = useState("")
  const [contractUrl, setContractUrl] = useState("")
  const [documentType, setdocumentType] = useState(""); 
  
  const [chatId, setChatId] = useState(null);
  // const [isModalOpen, setModalOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const { appendButton } = useInputField(); // Access the shared state
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isPromptChatVisible, setIsPromptChatVisible] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [finalResponse, setFinalResponse] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);

  // Function to handle card click
  const handleCardClick = (message, index) => {
    setChatInput(message); // Set the card's message in the input
    setClickedCardIndex(index); // Track which card is clicked
    setShowContractSearch(true); // Show ContractSearch
  };


  const handleInputChange = (e) => {
    const value = e.target.value;
    setChatInput(value);
    if (value.trim() === "") {
      setShowContractSearch(false); // Hide ContractSearch if input is empty
      setSelectedDoc(null);
    }
  };
  // Handle document selection
  const handleDocumentSelect = (doc) => {
    // console.log("dooccccc",doc);
    
    setContractKey(doc.key); // Store the selected document key
    setContractUrl(doc.url)
    setdocumentType(doc.document_type);
    // console.log("slecteted document key",doc.key);
    // console.log("slecteted document url",doc.url);
    // console.log("slecteted document typeee",doc.document_type);
    setSelectedDoc(doc); // Store the selected document
    setShowContractSearch(false); // Hide ContractSearch
  };
 
  

  useEffect(() => {
    const firstName = localStorage.getItem("firstName") || localStorage.getItem("first_name");
    const lastName = localStorage.getItem("lastname") || localStorage.getItem("last_name");
    const email = localStorage.getItem("email");

    setUser({
      firstName,
      lastName,
      email,
    });
  }, []);




  // const handleNewChat = async () => {


  //   // Add the user's message to chat history
  //   setChatHistory((prev) => [...prev, { sender: "user", message: chatInput }]);

  //   try {
  //     // Make an API call to fetch the response
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,

  //       { client_message: chatInput },
  //       {
  //         headers: {
  //           Authorization: `Token ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(response.data.chat_id);

  //     const newChatId = response?.data?.chat_id;
  //     setChatId(newChatId); // Store chatId in state
  //     console.log("Chat ID:", newChatId);

  //     toast.success("New Chat Started");
  //     const source = response?.data?.source || "unknown";
  //     const botMessage =
  //       response?.data?.data || "No response received from the server.";



  //     // Add the bot's message to the chat history, including the source
  //     setChatHistory((prev) => [
  //       ...prev,
  //       {
  //         sender: "system",
  //         message: `${botMessage} (Source: ${source})`,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.error("Error while fetching API response:", error);
  //     setChatHistory((prev) => [
  //       ...prev,
  //       {
  //         sender: "system",
  //         message: "Something went wrong. Please try again!",
  //       },
  //     ]);
  //   }

  // };


  // Function to initiate a new chat session

  const initiateNewChat = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.chat_id) {
        setChatId(response.data.chat_id);
        // console.log("New chat ID:", response.data.chat_id);

      } else {
        throw new Error("Chat ID not received");
      }
    } catch (error) {
      console.error("Error initiating chat:", error);
      toast.error("Failed to start a new chat. Please try again.");
    }
  };

  const effectRun = useRef(false);

  useEffect(() => {
    if (!effectRun.current) {
      initiateNewChat();
      effectRun.current = true;
    }
  }, []);

  const handleNewChat = () => {
    setChatId(null);
    initiateNewChat();
    setIsPromptChatVisible(false);
  };
  const handleSendMessage = async () => {
    if (!chatInput.trim()) {
      toast.error("Please enter a prompt!");
      return;
    }
  
    if (!selectedDoc) {
      toast.error("Please select a document!");
      return;
    }
  
    setIsLoadingResponse(true);
    setIsPromptChatVisible(true);
    // console.log("contract key parent", contractKey);
  
    try {
      // Establish WebSocket connection
      const ws = new WebSocket(
        `wss://wa-docusensa-backend-staging-bhbmcve5e7atgwcs.centralindia-01.azurewebsites.net/ws/prompt-library-answer/${chatId}/?token=${localStorage.getItem("token")}`
      );
  
      ws.onopen = () => {
        console.log("WebSocket connection established");
  
        // Send the initial payload
        ws.send(
          JSON.stringify({
            document_key: contractKey,
            prompt: chatInput,
          })
        );
      };
  
      let accumulatedText = ""; // To accumulate the response text
  
      ws.onmessage = (event) => {
        const data = event.data;
        // console.log("WebSocket response received:", data); 
  
        if (data.startsWith("data:")) {
          try {
            const jsonData = JSON.parse(data.replace("data: ", "").trim());
            // console.log("Parsed JSON data:", jsonData);
  
            if (jsonData.type === "progress" && jsonData.chunk) {
              accumulatedText += jsonData.chunk;
  
              setChatHistory((prev) => {
                const updatedHistory = [...prev];
  
                if (
                  updatedHistory.length === 0 ||
                  updatedHistory[updatedHistory.length - 1].sender !== "bot"
                ) {
                  return [
                    ...prev,
                    { id: Date.now(), sender: "bot", text: jsonData.chunk, final: false },
                  ];
                } else {
                  updatedHistory[updatedHistory.length - 1].text += jsonData.chunk;
                  return updatedHistory;
                }
              });
            }
  
            if (jsonData.type === "final") {
              // console.log("Final message completed:", accumulatedText);
  
              // Extract generated questions from final response
              const questionsArray = jsonData.data.generated_questions
                ? jsonData.data.generated_questions.split(";").map((q) => q.trim())
                : [];
  
              setGeneratedQuestions(questionsArray);
              setFinalResponse(accumulatedText);
  
              setChatHistory((prev) => {
                const updatedHistory = [...prev];
                if (
                  updatedHistory.length > 0 &&
                  updatedHistory[updatedHistory.length - 1].sender === "bot"
                ) {
                  updatedHistory[updatedHistory.length - 1].final = true;
                }
                return updatedHistory;
              });
  
              ws.close();
            }
          } catch (error) {
            console.error("Error parsing streamed JSON data:", error, "Raw Data:", data);
          }
        }
      };
  
      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        toast.error("WebSocket connection error. Please try again.");
      };
  
      ws.onclose = () => {
        console.log("WebSocket connection closed");
        setIsLoadingResponse(false);
      };
    } catch (error) {
      console.error("Error initializing WebSocket:", error);
      toast.error("Failed to send prompt. Please try again.");
      setIsLoadingResponse(false);
    }
  };
  
  
  
  

  useEffect(() => {
    // console.log("Progress bar updated:", progress);
  }, [progress]);



  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    // console.log("stored token in parent",storedToken);
    
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);


  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

  const handleUploadSuccess = () => {

    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();

  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleChatHistory = () => {
    setIsOpen(!isOpen);
  };


  const handleSearch = () => {
    console.log("Search input:", searchInput); // Replace with your search logic
  };

  const filteredContracts = useMemo(() => {
    if (!files || files.length === 0) return [];
    return files.filter((file) => {
      if (!file?.pdf_name) return false;
      return file.pdf_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [files, searchQuery]);
  const filteredData = useMemo(() => {
    const normalizedQuery = searchQuery
      .trim()
      .replace(/\s+/g, " ")
      .toLowerCase();

    if (!normalizedQuery) return [...folders, ...files];

    return [...folders, ...files].filter((item) =>
      (item.name || "").toLowerCase().includes(normalizedQuery)
    );
  }, [folders, files, searchQuery]);




  return (
    <div className='' >
      <Header name={"AI Copilot"} bell={bell} search={search} />




      {isPromptChatVisible ? (
        <PromptChat
          documentKey={contractKey}
          documentUrl={contractUrl}
          documentType={documentType}
          prompt={chatInput}
          chatId={chatId}
          chatInput={chatInput}
          isLoadingResponse={isLoadingResponse}
          finalResponse={finalResponse || ""}  // Ensure a fallback value
          generatedQuestions={generatedQuestions || []}
        />
      ) : (

        <div className={`ai-container ${isMenuOpen ? 'menu-open' : ''}`} >
          <div className={`copilot-page ${isMenuOpen ? 'reduced-width' : ''}`} >
            <div className='upload-mainpage flex justify-center items-center' >
              {/* style={{
            background: 'linear-gradient(130.93deg, rgba(139, 85, 221, 0.44) 10.67%, rgba(187, 66, 174, 0.44) 85.46%)'
          }} */}
              <div className={`${isMenuOpen ? 'ml-12' : 'ml-12'} flex flex-col justify-center items-center`}>

                <div className={`${isMenuOpen ? 'ml-12' : 'ml-0'} AI-CP-ChatHistoryContainer flex gap-4`}>
                  <button
                    class="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] gap-[8px] shadow-[1px_3px_6px_rgba(0,0,0,0.1),5px_10px_11px_rgba(0,0,0,0.09),11px_23px_15px_rgba(0,0,0,0.05),20px_41px_18px_rgba(0,0,0,0.01),31px_64px_20px_rgba(0,0,0,0)] flex items-center justify-center"
                    onClick={handleNewChat}
                  >
                    <img src="/newChat-icon.svg" alt="New Chat Icon" class="w-4 h-4" />
                  </button>                <button className="chat-historyBtn" onClick={toggleChatHistory}>
                    <span>Chat History</span>
                    <img src={arrowForward2} alt="Forward arrow" />
                  </button>
                  <ChatHistory isOpen={isOpen} />
                </div>


                <div className="topContainer inline-flex flex-col justify-center items-center">


                  <h6>
                    Hi {user.firstName}
                  </h6>
                  <span>What Can I Help You With Today?</span>
                  <p >
                    Type your request below with one of <br />these suggestions.
                  </p>

                </div>



                <div className='secondContainer'>
                  <div className="card_container">
                    {/* Card 1 */}
                    <div
                      className={`card ${clickedCardIndex === 0 ? "card-disabled" : ""}`}
                      onClick={() => handleCardClick("Summarize the data from Contract", 0)}
                    >
                      <img src={summarizeIcon} alt="Summarize Icon" />
                      <p>Summarize the data from Contract</p>
                    </div>

                    {/* Card 2 */}
                    <div
                      className={`card ${clickedCardIndex === 1 ? "card-disabled" : ""}`}
                      onClick={() =>
                        handleCardClick("Extract payment terms from Invoice", 1)
                      }
                    >
                      <img src={InvoiceIcon} alt="Invoice Icon" />
                      <p>Extract payment terms from Invoice</p>
                    </div>

                    {/* Card 3 */}
                    <div
                      className={`card ${clickedCardIndex === 2 ? "card-disabled" : ""
                        }`}
                    // onClick={() =>
                    //   handleCardClick(
                    //     "Analyze Upcoming trends in this Spreadsheet",
                    //     2
                    //   )
                    // }
                    >
                      <img src={SpreadsheetIcon} alt="spreadsheet Icon" />
                      <p>Analyze Upcoming trends in this Spreadsheet</p>
                    </div>

                  </div>
                  <div className="explorePrompt">
                    <button onClick={() => setIsPromptModalOpen(true)}>
                      <span>Explore Prompts</span>
                      <img src={arrowForward} alt="Forward arrow" />
                    </button>
                    <ExplorePrompt
                      isOpen={isPromptModalOpen}
                      onClose={() => setIsPromptModalOpen(false)}
                      onSelectPrompt={(prompt) => {
                        setChatInput(prompt); // Update the input field
                        setShowContractSearch(true); // Trigger the ContractSearch
                      }}
                    />
                  </div>

                </div>

                <div className='thirdContainer'>
                  {/* Input and Send Button */}

                  <div>
                    <button
                      onClick={() => setIsModalOpen(true)} // Opens the modal
                      className="uploadButton border-gray-300 rounded-lg flex items-center space-x-2 p-2"
                    >
                      <span className="font-bold text-base">Upload</span>
                      <img src={UploadIcon} alt="Upload Icon" className="w-6 h-6" />
                    </button>

                    {/* Upload Modal */}
                    <DocumentUploadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} type={"document"} />
                    {/* <UploadModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onUploadSuccess={handleUploadSuccess}
                    type={"document"}
                  /> */}
                  </div>


                  <div
                    className="chat-input-section flex items-center"
                  >
                    {/* Input Field */}
                    <input
                      type="text"
                      className={`chat-input flex-1 rounded-full "
                      }`}
                      placeholder="How can I help? Type your question or choose a prompt"
                      value={chatInput}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />

                    {/* Selected Document or ContractSearch */}
                    {selectedDoc ? (
                      <button
                        className="selected-doc-button flex items-center gap-2"
                        style={{
                          width: "auto", // Set button width
                          height: "29px", // Set button height
                          background: "rgba(254, 249, 247, 1)", // Set background color
                          border: "1px solid rgba(0, 0, 0, 0.5)", // Set border style
                          borderRadius: "6px", // Set border radius
                          marginRight: "320px", // Margin adjustment
                          display: "flex", // Ensure content aligns flexibly
                          alignItems: "center", // Center items vertically
                          justifyContent: "space-between", // Space between content
                          padding: "0 10px", // Horizontal padding for inner spacing
                          fontSize: "14px", // Font size for button text
                          color: "#000", // Text color
                        }}
                        onClick={() => setShowContractSearch(true)} // Reopen ContractSearch
                      >
                        <span>{selectedDoc.name}</span>
                        <span
                          className="ml-2 text-red-500 font-bold cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent button click event
                            setSelectedDoc(null); // Clear selected document
                            setContractKey(""); // Clear document key
                            setShowContractSearch(true)
                          }}
                        >
                          ×
                        </span>
                      </button>
                    ) : (
                      chatInput.trim() && showContractSearch && (
                        <div className="ml-2">
                          <ContractSearch onDocumentSelect={handleDocumentSelect} />
                        </div>
                      )
                    )}

                    {/* Send Button */}
                    <button className="chat-button" onClick={handleSendMessage}>
                      <img
                        src="/send.svg"
                        alt="send logo"
                        className="send-icon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </button>
                  </div>

                </div>

              </div>


            </div>

          </div>
        </div>)}
    </div >




  )
}