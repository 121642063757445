  import React, {
      useState,
      useEffect,
      useCallback,
      useRef,
      useMemo,
    } from "react";
    import { FaSearch } from "react-icons/fa";
    import { useNavigate } from "react-router-dom";
    import { Header } from "../../components/Header/Header";
    import UploadModal from "../Contract/UploadModal";
    import FilterModal from "../Contract/filterModal";
    import bell from "../../Images/ai-bell.png";
    import search from "../../Images/search-big.jpg";
    import axios from "axios";
    import "../Contract/contract_data.css";
    import Spinner from "../Contract/Spinner";
    
    const ContractTable = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [isBulkDelete, setIsBulkDelete] = useState(false);
      const [exportInProgress, setExportInProgress] = useState(false);
      const [invoices, setinvoices] = useState([]);
      const [token, setToken] = useState(null);
      const [currentPage, setCurrentPage] = useState(1);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
      const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
      const [editContractId, setEditContractId] = useState(null);
      const [editedContractData, setEditedContractData] = useState({});
      const [loading, setLoading] = useState(false);
      const editRowRef = useRef(null);
      const [selectedinvoices, setSelectedinvoices] = useState([]);
      const [selectedContractKeys, setSelectedContractKeys] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
      const [popupVisible, setPopupVisible] = useState(false);
      const [popupTitle, setPopupTitle] = useState("");
      const [popupMessage, setPopupMessage] = useState("");
      const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
      const [contractToDelete, setContractToDelete] = useState(null);
      const actionMenuRef = useRef(null);
      const [hoveredRow, setHoveredRow] = useState(null);
    
      const [filterApplied, setFilterApplied] = useState(false);
      const navigate = useNavigate();
      const handleFilterApply = (filteredinvoices) => {
        setinvoices(filteredinvoices);
        setFilterApplied(true);
        toggleFilterModal();
      };
    
      useEffect(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
          setToken(storedToken);
        } else {
          console.error("Token is missing from localStorage");
        }
      }, []);
    
      const toggleModal = () => {
        if (!token) {
          showPopup("Session Expired", "Session expired. Please log in again.");
          return;
        }
        setIsModalOpen((prev) => !prev);
      };
    
      const handleExport = async () => {
        if (!token) {
          showPopup("Session Expired", "Session expired. Please log in again.");
          return;
        }
      
        if (!selectedinvoices || selectedinvoices.length === 0) {
          showPopup("Error", "Please select at least one invoice to export.");
          return;
        }
      
        setExportInProgress(true);
      
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/invoice/export-invoice/`,
            { invoice_keys: selectedContractKeys },
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
              responseType: "blob", 
            }
          );
      
          
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoices_export.csv"); 
          document.body.appendChild(link);
          link.click(); 
          document.body.removeChild(link);
      
          showPopup("Success", "Invoices exported successfully.");
        } catch (error) {
          if (error.response) {
            const message = error.response.data?.msg || "Failed to export invoices.";
            showPopup("Error", message);
          } else {
            showPopup("Error", "An unexpected error occurred. Please try again.");
          }
        } finally {
          setExportInProgress(false);
        }
      };
      
    
      const handleCancel = () => {
        setSelectedinvoices([]);
    
        setIsModalOpen(false);
        setIsFilterModalOpen(false);
        setPopupVisible(false);
    
        setConfirmDeleteVisible(false);
      };
    
      const fetchinvoices = useCallback(async () => {
        if (!token) return;
        setLoading(true);
    
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/invoice/invoices/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          console.log("response", response.data);
          setinvoices(response.data);
        } catch (error) {
          console.error("Error fetching invoices:", error);
        } finally {
          setLoading(false);
        }
      }, [token]);
    
      const tableBodyRef = useRef(null);
    
      const scrollToTop = () => {
        if (tableBodyRef.current) {
          tableBodyRef.current.scrollTop = 0;
        }
      };
    
      const handleSelectAll = () => {
        if (selectedinvoices.length === invoices.length) {
          setSelectedinvoices([]);
          setSelectedContractKeys([]);
        } else {
          setSelectedinvoices(invoices.map((contract) => contract.id));
          setSelectedContractKeys(invoices.map((contract) => contract.key));
        }
      };
      
      const filteredinvoices = useMemo(() => {
        const query = searchQuery?.toLowerCase() || ""; 
        return invoices.filter((invoice) => {
          const customerName = invoice?.details?.["Customer name"]?.toLowerCase() || ""; 
          const vendorName = invoice?.details?.["Vendor name"]?.toLowerCase() || ""; 
      
          
          return customerName.includes(query) || vendorName.includes(query);
        });
      }, [invoices, searchQuery]);
      
      
      
    
      const indexOfLastContract = currentPage * rowsPerPage;
      const indexOfFirstContract = indexOfLastContract - rowsPerPage;
      const currentinvoices = useMemo(() => {
        const indexOfLastContract = currentPage * rowsPerPage;
        const indexOfFirstContract = indexOfLastContract - rowsPerPage;
        return filteredinvoices.slice(indexOfFirstContract, indexOfLastContract);
      }, [filteredinvoices, currentPage, rowsPerPage]);
    
      const totalPages = useMemo(
        () => Math.ceil(filteredinvoices.length / rowsPerPage),
        [filteredinvoices, rowsPerPage]
      );
      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(1);
      };
    
      const handleCheckboxChange = (id, key) => {
        if (selectedinvoices.includes(id)) {
          setSelectedinvoices(selectedinvoices.filter((contractId) => contractId !== id));
          setSelectedContractKeys(selectedContractKeys.filter((contractKey) => contractKey !== key));
        } else {
          setSelectedinvoices([...selectedinvoices, id]);
          setSelectedContractKeys([...selectedContractKeys, key]);
        }
      };
      
    
      useEffect(() => {
        if (token) {
          fetchinvoices();
        }
      }, [fetchinvoices, token]);
    
      const handleUploadSuccess = () => {
        fetchinvoices();
        toggleModal();
      };
    
      const handleCloseModal = () => {
        toggleModal();
        fetchinvoices();
      };
    
      const handlePageChange = (event) => {
        const newPage = Number(event.target.value);
        if (newPage > 0 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
      };
    
      const toggleActionMenu = (index) => {
        setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
      };
    
      const toggleFilterModal = () => {
        setIsFilterModalOpen((prev) => !prev);
      };
    
      const handleViewMore = (contract) => {
        console.log("contract" ,contract);
        navigate("/Invoices/Invoice_Details", {
            state: { invoiceKey: contract.key },
        });
    };

    
    
    const enableEdit = (invoice) => {
      if (invoice) {
        setIsActionMenuOpen(null);
        setEditContractId(invoice.id);
        setEditedContractData({
          ...invoice,
          details: { ...invoice.details },
        });
      } else {
        setEditContractId(null);
        setEditedContractData({});
      }
    };
    
      
    
      const handleEditChange = (field, value) => {
        if (field.startsWith("details.")) {
          const key = field.split(".")[1];
          setEditedContractData((prev) => ({
            ...prev,
            details: {
              ...prev.details,
              [key]: value,
            },
          }));
        } else {
          setEditedContractData((prev) => ({
            ...prev,
            [field]: value,
          }));
        }
      };
      
    
      const handleClickOutside = (event) => {
        if (
          actionMenuRef.current &&
          !actionMenuRef.current.contains(event.target)
        ) {
          setIsActionMenuOpen(null);
        }
        if (
          confirmDeleteVisible &&
          event.target.closest(".popup-overlay") === null &&
          !event.target.closest(".popup-box-delete")
        ) {
          setConfirmDeleteVisible(false);
        }
      };
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [confirmDeleteVisible, isActionMenuOpen]);
    
      const showPopup = (title, message) => {
        setPopupTitle(title);
        setPopupMessage(message);
        setPopupVisible(true);
      };
    
      const closePopup = () => {
        setPopupVisible(false);
      };
      const handleDelete = (contractId) => {
        if (!token) {
          showPopup("Session Expired", "Session expired. Please log in again.");
          return;
        }
        setIsBulkDelete(false);
        setContractToDelete(contractId);
        setConfirmDeleteVisible(true);
      };
    
      const handleBulkDelete = () => {
        if (!token) {
          showPopup("Session Expired", "Session expired. Please log in again.");
          return;
        }
    
        if (!selectedinvoices || selectedinvoices.length === 0) {
          showPopup("Error", "Please select at least one contract to delete.");
          return;
        }
    
        setIsBulkDelete(true);
        setConfirmDeleteVisible(true);
      };
    
      const handleEditSave = async (invoiceId, invoiceKey) => {
        if (!token) {
          showPopup("Session Expired", "Session expired. Please log in again.");
          return;
        }
      
        const editedData = {
          "Issued on": editedContractData.details?.["Issued on"],
          "Customer name": editedContractData.details?.["Customer name"],
          "Vendor name": editedContractData.details?.["Vendor name"],
          "Total due amount": editedContractData.details?.["Total due amount"],
          "Due date": editedContractData.details?.["Due date"],
        };
      
        const note = "Updated invoice details"; 
      
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/invoice/edit-invoice-details/${invoiceKey}/`,
            { edited_data: editedData, note },
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
      
          if (response.status === 200) {
            const updatedInvoice = response.data.data;
      
            setinvoices((prevInvoices) =>
              prevInvoices.map((invoice) =>
                invoice.id === invoiceId ? { ...invoice, details: updatedInvoice } : invoice
              )
            );
      
            setEditContractId(null);
            showPopup("Success", "Invoice details updated successfully.");
          }
        } catch (error) {
          console.error("Error saving invoice:", error);
          showPopup("Error", "Failed to update invoice. Please try again.");
        }
      };
      
      
      
    
      const confirmDelete = async () => {
        if (!token) {
          showPopup("Error", "Session expired. Please log in again.");
          return;
        }
      
        
        const idsToDelete = isBulkDelete ? selectedinvoices : [contractToDelete];
      
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/v1/invoice/delete-invoice/`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
              data: { invoice_ids: idsToDelete }, 
            }
          );
      
          const { Deleted_invoices = [], Failed_deletions = [] } = response.data;
      
          if (Deleted_invoices.length > 0) {
            
            setinvoices((previnvoices) =>
              previnvoices.filter((invoice) => !Deleted_invoices.includes(invoice.id))
            );
      
          
            if (isBulkDelete) {
              setSelectedinvoices([]);
            }
      
            
            showPopup(
              "Success",
              `${Deleted_invoices.length} invoice(s) deleted successfully.`
            );
          }
      
          if (Failed_deletions.length > 0) {
            const failureMessages = Failed_deletions.map(
              (failure) => `Invoice ID ${failure.invoice_id}: ${failure.error}`
            ).join("\n");
            showPopup("Deletion Failed", failureMessages);
          }
        } catch (error) {
          console.error("Error deleting invoices:", error);
          showPopup("Error", "Failed to delete invoice(s). Please try again.");
        } finally {
          setConfirmDeleteVisible(false);
          setContractToDelete(null);
          fetchinvoices(); 
        }
      };
      
    
      useEffect(() => {
        if (editContractId) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [editContractId]);
    
      return (
        <>
          {loading && <Spinner />}
          <div className="full-container">
            <div className="">
              <Header name="Invoices" icon={""} />
            </div>
    
            <div className="contract-table-container">
              <div className="contract-table-header">
                <div className="search-filter-container">
                  <div className="search-box">
                    <img src="search.svg" alt="" />
                    <input
                      type="text"
                      placeholder="Search Invoices Here"
                      className="Input-space"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <button className="filter-button" onClick={toggleFilterModal}>
                    <img src="/filter.svg" alt="Filter Icon" />
                  </button>
                </div>
                {selectedinvoices.length > 0 ? (
                  <div className="button-cat">
                    <button
                      onClick={handleExport}
                      disabled={exportInProgress || selectedinvoices.length === 0}
                    >
                      <img src="./export-icon-blue.svg" alt="Export Icon" />
                      {exportInProgress ? "Exporting..." : "Export"}
                    </button>
                    <button
                      onClick={handleBulkDelete}
                      disabled={selectedinvoices.length === 0}
                    >
                      <img src="./delete-icon-red.svg" alt="Delete Icon" />
                      Delete
                    </button>
    
                    <button onClick={handleCancel} className="cancel-bulk">
                      <img src="/cancel_icon.svg" alt="Cancel Icon" />
                    </button>
                  </div>
                ) : (
                  <button className="upload-button bg-[#C95EBE] " onClick={toggleModal}>
                    <img
                      className="upload-icon w-8 h-8"
                      src="/upload_icon_white.svg"
                      alt="Upload Icon"
                    />
                    <span className="text-white">
    Upload Invoice
  </span>

                  </button>
                )}
              </div>
    
              <div className="container">
                <table className="contract-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedinvoices.length === invoices.length &&
                            invoices.length > 0
                          }
                        />
                      </th>
                      
                      <th>Date Issued</th>
                      <th>Customer Name</th>
                      <th>Vendor Name</th>
                      <th>Amount</th>
                      <th>Due Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody
    className={currentinvoices.length > rowsPerPage ? "scrollable" : ""}
    style={{
      overflowY: currentinvoices.length > rowsPerPage ? "auto" : "hidden",
      maxHeight: "400px",
    }}
  >
    {loading ? (
      <tr>
        <td colSpan="6" style={{ textAlign: "center" }}>
          <Spinner />
        </td>
      </tr>
    ) : invoices.length > 0 ? (
      currentinvoices.map((contract, index) => (
        <tr
          key={index}
          ref={editContractId === contract.id ? editRowRef : null}
          style={{
            background: hoveredRow === index ? "#F8F3FF" : "transparent",
            boxShadow: hoveredRow === index
              ? "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
              : "none",
          }}
          onMouseEnter={() => setHoveredRow(index)}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <td>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={selectedinvoices.includes(contract.id)}
              onChange={() => handleCheckboxChange(contract.id, contract.key)}
            />
          </td>

          {editContractId === contract.id ? (
  <>
    <td>
      <input
        type="date"
        value={editedContractData.details?.["Issued on"] || ""}
        onChange={(e) =>
          handleEditChange("details.Issued on", e.target.value)
        }
        className="edit-contract"
      />
    </td>
    <td>
      <input
        type="text"
        value={editedContractData.details?.["Customer name"] || ""}
        onChange={(e) =>
          handleEditChange("details.Customer name", e.target.value)
        }
        className="edit-contract"
      />
    </td>
    <td>
      <input
        type="text"
        value={editedContractData.details?.["Vendor name"] || ""}
        onChange={(e) =>
          handleEditChange("details.Vendor name", e.target.value)
        }
        className="edit-contract"
      />
    </td>
    <td>
      <input
        type="number"
        value={editedContractData.details?.["Total due amount"] || ""}
        onChange={(e) =>
          handleEditChange("details.Total due amount", e.target.value)
        }
        className="edit-contract"
      />
    </td>
    <td>
      <input
        type="date"
        value={editedContractData.details?.["Due date"] || ""}
        onChange={(e) =>
          handleEditChange("details.Due date", e.target.value)
        }
        className="edit-contract"
      />
    </td>
    <td>
      <button
        onClick={() => handleEditSave(contract.id, contract.key)}
        className="save_Design"
      >
        Save
      </button>
    </td>
  </>
) : (
  <>
    <td>
  <span
    style={{
      color: "#B793ED", 
      textDecoration: "underline", 
      cursor: "pointer",
    }}
    onClick={() =>
      navigate("/Invoices/Invoice_Details", {
        state: { invoiceKey: contract.key },
      })
    }
  >
    {contract.details?.["Issued on"] || "N/A"}
  </span>
</td>

    <td>{contract.details?.["Customer name"] || "N/A"}</td>
    <td>{contract.details?.["Vendor name"] || "N/A"}</td>
    <td>{contract.details?.["Total due amount"] || "N/A"}</td>
    <td>{contract.details?.["Due date"] || "N/A"}</td>
    <td>
                <img
                  src="./action.svg"
                  alt="Actions"
                  width="20"
                  height="20"
                  onClick={() => toggleActionMenu(index)}
                  style={{ cursor: "pointer" }}
                />
                {isActionMenuOpen === index && (
                  <div className="action-menu" ref={actionMenuRef}>
                    <div
                      className="action-item"
                      onClick={() => enableEdit(contract)}
                    >
                      <img
                        src="./edit.svg"
                        alt="Edit Icon"
                        className="action-icon"
                      />
                      <p>Edit</p>
                    </div>
                    <div
                      className="action-item"
                      onClick={() => handleDelete(contract.id)}
                    >
                      <img
                        src="./delete.svg"
                        alt="Delete Icon"
                        className="action-icon"
                      />
                      <p>Delete</p>
                    </div>
                  </div>
                )}
              </td>
  </>
          )}
        </tr>
      ))
    ) : (
      <div className="no-contracts-icon">
        {filterApplied ? (
          <>
            <img src="/no-contracts.svg" alt="No invoicesss Found" />
            <p className="no-contracts-para">
              No invoices found for the selected filters.
            </p>
          </>
        ) : (
          <>
            <img src="/no-contracts.svg" alt="No invoices Available" />
            <p className="no-contracts-para">
              Looks like your table is empty!
            </p>
          </>
        )}
      </div>
    )}
  </tbody>

                </table>
                {invoices.length > 0 && (
                  <div className="pagination">
                    <div className="pagination-dropdown">
                      <select
                        value={currentPage}
                        onChange={(e) => setCurrentPage(Number(e.target.value))}
                        className="page-selector"
                      >
                        {Array.from({ length: totalPages }, (_, index) => (
                          <option key={index} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="contentDown">of {totalPages} pages</p>
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      style={{
                        cursor: currentPage === 1 ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        src="decrease.svg"
                        alt="Previous Page"
                        style={{ height: "12px", width: "12px" }}
                      />
                    </button>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      style={{
                        cursor:
                          currentPage === totalPages ? "not-allowed" : "pointer",
                      }}
                    >
                      <img
                        src="increase.svg"
                        alt="Next Page"
                        style={{ height: "12px", width: "12px" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
    
            {popupVisible && (
              <div className="popup-overlay">
                <div className="popup-box">
                  <h2>{popupTitle}</h2>
                  <p>{popupMessage}</p>
                  <button onClick={closePopup} className="popup-close-button">
                    Close
                  </button>
                </div>
              </div>
            )}
    
            {confirmDeleteVisible && (
              <div className="popup-overlay">
                <div className="popup-box-delete">
                  <h2>Confirm Deletion</h2>
                  <p>
                    {isBulkDelete
                      ? "Are you sure you want to delete the selected invoices? This action cannot be undone."
                      : "Are you sure you want to delete this invoice? This action cannot be undone."}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={confirmDelete}
                      className="popup-close-button-confirm"
                    >
                      Yes, Delete
                    </button>
                    <button
                      onClick={() => setConfirmDeleteVisible(false)}
                      className="popup-close-button-delete"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <UploadModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onUploadSuccess={handleUploadSuccess}
              type={"Invoices"}
            />
    
    <FilterModal
  isOpen={isFilterModalOpen}
  onClose={() => setIsFilterModalOpen(false)}
  token={token}
  setInvoices={setinvoices}
  isInvoice={true}
fetchinvoices={fetchinvoices}
/>

          </div>
        </>
      );
    };
    
    export default ContractTable;
    